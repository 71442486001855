





$calendar3_cell_width:  75px;
$calendar3_cell_height: 75px;
$calendar3_day_inset: 4px;

$calendar3_top_gutter: 5px;
$calendar3_left_gutter:  17px;
$calendar3_right_gutter: 17px;



.my_dashboard_calendar
{
	background: $color_pitch_light;
	border-radius: 5px;
	width: 100%;
	
	padding: 10px;
	
	box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
	margin-bottom: 10px;
	
	// display: flex;
	// flex-direction: column;
	// justify-content: center;
	// align-items: center;
	
	// @media only screen and (max-width: 725px)
	// {
	// 	overflow-x: scroll;
	// }
}



.calendar3_week_stack
{
	display: grid;
	grid-template-columns: 1.5fr repeat(7, 1fr) 1.5fr;
}


.calendar3_year
{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	
	padding-right: $calendar3_left_gutter;
	padding-bottom: $calendar3_top_gutter;
	
	color: #0005;
	
	font-weight: 700;
	
	user-select: none;
}


.calendar3_month
{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	
	padding-right: $calendar3_left_gutter;
	
	color: #0063;
	
	user-select: none;
}


.calendar3_week_label
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	padding-left: $calendar3_right_gutter;
	padding-bottom: $calendar3_top_gutter;
	
	color: #5002;
	
	font-size: 15px;
	
	user-select: none;
}


.calendar3_week_num
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	padding-left: $calendar3_right_gutter;
	padding-bottom: $calendar3_top_gutter;
	
	color: #8003;
	
	font-size: 15px;
	
	user-select: none;
}


.calendar3_day_of_week
{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	
	width: $calendar3_cell_width;
	height: $calendar3_cell_height;
	
	padding-bottom: $calendar3_top_gutter;
	
	color: #0003;
	
	font-weight: 700;
	
	user-select: none;
}


.calendar3_day
{
	position: relative;
	
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
	
	width: $calendar3_cell_width;
	height: $calendar3_cell_height;
	
	color: #0006;
	//background: #f8f8f8;
	
	font-weight: 600;
	font-size: 14px;
	
	transition: .2s;
	
	user-select: none;
	
	cursor: pointer;
	
	border: 1px solid rgba(207, 207, 207, 0.247);
	
	
	p
	{
		z-index: 10;
		margin-top: 5px;
	}
	
	
	&::before
	{
		@include absolute();
		
		top:    $calendar3_day_inset;
		right:  $calendar3_day_inset;
		bottom: $calendar3_day_inset;
		left:   $calendar3_day_inset;
		
		border-radius: 5px;
		
		transition: .2s;
	}
	
	&:hover
	{
		color: #000d;
		
		&::before
		{
			background: $color_eggplant_light;
			box-shadow: 0 0 1px 2px #0001;
			opacity: 0.5;
		}
		
		&.today
		{
			&::before
			{
				box-shadow: 0 0 1px 2px #4488;
				opacity: 0.5;
			}
		}
	}
	
	
	&.selected
	{
		color: #000;
		
		&::before
		{
			background: $color_eggplant_dark;
			box-shadow: 0 0 1px 2px #0002;
			opacity: 0.5;
		}
	}
	
	&.selected:hover
	{
		&::before
		{
			background: $color_eggplant_light;
			box-shadow: 0 0 1px 2px #0005;
			opacity: 0.5;
		}
	}
	
	&.today.selected
	{
		&::before
		{
			background: $color_eggplant_dark;
			box-shadow: 0 0 1px 2px #4488;
			opacity: 0.5;
		}
	}
}


.odd_month
{
	background: #ccf3;
}


.today
{
	&::before
	{
		background-color: #dbf3;
		box-shadow: 0 0 1px 1px #4488;
	}
}


.weekend
{
	background: #fbb3;
}


.odd_month.weekend
{
	background: #c9c4;
}





.calendar3_pickers
{
	display: flex;
	//justify-content: space-around;
	justify-content: center;
	
	margin-top: 24px;
}

.calendar3_event_form
{
	display: flex;
	//justify-content: space-around;
	justify-content: center;
	
	margin-top: 24px;
}

.calendar3_date,
.calendar3_time
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	max-width: 40%;
	max-width: 160px;
	
	margin: 4px 12px;
}







