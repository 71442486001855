// Content_Issueuser 
	.issuedUser___wrapper
	{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		margin-top: 3rem;
		transition: all 1s ease;
		
		> div {
			margin: .2rem 0;
		}
	}
// ============================