@import url("https://use.fontawesome.com/releases/v5.14.0/css/all.css");




.assets_container_modal {
	position: relative;
	width: 70%;
	justify-content: space-between;
	align-items: center;
	
	height: 100%;
	min-width: 10rem;
	
	padding-bottom: 20px;
	padding-left: 20px;
	overflow-y: scroll;
	
	margin-right: 15px;
}

.tree_main {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: fit-content;
	height: fit-content;
	min-width: 10rem;
	border-radius: 10px;
	left: 0;
	margin-right: 0;
	flex-wrap: wrap;
	
	
	padding-bottom: 20px;
	
	margin-bottom: 40px;
}



.tree_nav_main
{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: fit-content;
	
	padding-left: 10px;
	width: 100%;
}


.back_to_assets_button {
	height: 40px;
}


.component_tree_flags_icon {
	margin-left: 10px;
	
	height: 18px;
	width: 18px;
	
	color: rgb(211, 69, 44);
}

.component_with_flags {
	display: flex;
}



.trunk_not_expanded {
	background: #000000;
	width: 0.1rem;
	height: 2rem;
	position: absolute;
	flex-grow: 1;
	left: -3.75rem;
	margin-top: -1.25rem;
	z-index: -1;
}

.trunk_expanded {
	background: #000000;
	width: 0.1rem;
	height: 100%;
	position: absolute;
	flex-grow: 1;
	left: -3.75rem;
	margin-top: -1.25rem;
	z-index: -1;
}

.branch {
	background: #000000;
	width: 65px;
	height: 0.1rem;
	position: absolute;
	margin: none;
	margin-left: -3.8rem;
	margin-top: 0.6rem;
	z-index: -2;
}

.tree_span {
	position: relative;
	
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	padding-right: 10px;
	
	font-size: 25px;
}

.tree_root_icon {
	color: $color_primary_dark;
	size: 25px;
}

.ul_tree {
	list-style: none;
	width: fit-content;
	li {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
}

.ul_main {
	position: relative;
	list-style: none;
	// li {
	// 	padding-top: 0.5rem;
	// 	padding-bottom: 0.5rem;
	// }
	
	&.is_selected {
		background: #fd84;
		border-radius: 2px;
	}
	
	&.is_highlighted {
		// background: rgba(154, 215, 255, .4);
		// border-radius: 2px;
	}
	
	&.is_hovered {
		background: #fd88;
		border-radius: 2px;
	}
	
	cursor: pointer;
	
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	
	width: fit-content;
	padding-bottom: 10px;
	border-radius: 2px;
	
	&:hover {
		// background: #eef;
	}
}

.ul_sub {
	list-style-type: none;
	position: relative;
	padding: 0;
	overflow: visible;
	margin-left: 1.9rem;
	margin-bottom: 0;
	height: fit-content;
	//width: fit-content;

	li {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		a {
			focus {
				background: #000000;
			}
		}
	}
}

.icon_connector {
	position: absolute;
	left: -4rem;
	align-items: center;
	justify-content: flex-end;
	top: 0.4rem;
	font-size: 10px;
}

.caret {
	position: absolute;
	font-size: 20px;
	align-items: center;
	justify-content: flex-end;
	svg {
		height: fit-content;
		width: fit-content;
	}
}

// .expanded {
// 	position: absolute;
// 	font-size: 10px;
// 	align-items: center;
// 	justify-content: flex-end;
// 	top: 6px;
// 	left: -10px;
// 	svg {
// 		height: fit-content;
// 		width: fit-content;
// 	}
// }

.circle {
	position: absolute;
	font-size: 15px;
	align-items: center;
	justify-content: center;
	margin-top: 0.2rem;
	padding: 0;
	margin-left: 0rem;
	z-index: -1;
	svg {
		height: fit-content;
		width: fit-content;
	}
}

.sydney_icon {
	margin-right: 1rem;
	width: fit-content;
	height: fit-content;
}

.square {
	font-size: 25px;
}








.tree_node_focused {
	border: 1px solid #848484;
	border-radius: 5px;
	padding-left: 5px;
	padding-right: 5px;
}





$tree_icon_size: 20px;





.tree_node {
	position: relative;

	display: flex;
	flex-direction: column;

	//background: #0ff3;

	user-select: none;

	list-style: none;
	margin: 0;
	padding-right: 8px;
	
	
	border: 1px solid #0000;
	

	&.is_selected {
		background: #fd88;
		border-radius: 2px;
		border-color: #000a;
	}
	
	&.is_highlighted {
		// background: rgba(154, 215, 255, .2);
		// border-radius: 2px;
		// border-color: #0008;
	}

	&.is_hovered > .tree_junction {
		background: #fd84;
		border-radius: 2px;
		border-color: #0006;
	}
}

// .tree_node:last-child
// {
// 	&::before
// 	{
// 		border-radius: 0 0 0 $tree_icon_size / 3;
// 	}
// }

// .tree_node:nth-child(2n)
// {
// 	background: #0cc3;

// 	&:hover
// 	{
// 		background: #0cc5;
// 	}
// }

.tree_leaf
{
	display: flex;
	align-items: center;
}

.tree_branch
{
	//background: red;
	
	&.has_lower_siblings
	{
		// Vertical
		&::after
		{
			content: "";
			position: absolute;
			top: calc($tree_icon_size / -2);
			left: calc($tree_icon_size / 2 - 1px);
			border-left: 2px solid #ddd;
			border-radius: 0;
			width: $tree_icon_size + 2px;
			height: 100%;
			pointer-events: none;
			z-index: 1;
		}
	}
	
	// & > :last-child
	// {
	// 	background: magenta;
	// }
}

.tree_twig
{
	display: flex;
	flex-direction: row;
	align-items: center;
	
	height: $tree_icon_size;
	padding-left: $tree_icon_size * 1.1;
	
	font-weight: 300;
	color: #000a;
	
	cursor: pointer;
	
	transition: 0.15s;
	
	&:hover
	{
		background: #eef;
		color: #000;
		
		.tree_leaf_icon
		{
			color: lighten(#15638e, 4%);
		}
		
		&::before
		{
			border-bottom: 2px solid #000;
		}
	}
	
	// Horizontal
	&::before
	{
		content: "";
		position: absolute;
		top: calc($tree_icon_size / -2 - 1px);
		left: calc($tree_icon_size / 2 - 1px);
		border-bottom: 2px solid #0003;
		border-radius: 0;
		width: $tree_icon_size + 2px;
		height: $tree_icon_size;
		pointer-events: none;
	}
	
	// Vertical
	&::after
	{
		content: "";
		position: absolute;
		top: calc($tree_icon_size / -2 + 1px);
		left: calc($tree_icon_size / 2 - 1px);
		border-left: 2px solid #ddd;
		border-radius: 0;
		width: $tree_icon_size + 2px;
		height: $tree_icon_size;
		pointer-events: none;
	}
	
	&.show_trace::after
	{
		border-left: 2px solid #000;
	}
}

// .has_lower_siblings {
// 	background: rebeccapurple;
	
// 	& *
// 	{
// 		background: $color_foggy;
// 	}
// }
.is_last {
	//background: blue;
}
.has_siblings {
	//background: lime;
}

.tree_junction
{
	display: flex;
	flex-direction: row;
	//background: orange;
	align-items: center;
	
	font-weight: 500;
	
	cursor: pointer;
	
	transition: 0.15s;
	
	&.tree_junction:hover
	{
		background: rgba(255, 155, 20, .4);
		
		&::before
		{
			border-bottom: 2px solid #000;
		}
	}
	
	&::before
	{
		content: "";
		position: absolute;
		top: calc($tree_icon_size / -2 - 1px);
		left: calc($tree_icon_size / 2 - 1px);
		border-bottom: 2px solid #ddd;
		border-radius: 0;
		width: $tree_icon_size + 2px;
		height: $tree_icon_size + 2px;
		pointer-events: none;
	}
}

.tree_junction_icon
{
	width: $tree_icon_size + $tree_icon_size;
	height: $tree_icon_size;
	
	padding-left: $tree_icon_size;
	
	text-align: center;
	
	cursor: pointer;
	
	//color: #15638E;
	color: lighten(#15638e, 27%);
	
	z-index: 2;
	
	&:hover
	{
		color: lighten(#15638e, 4%);
	}
}

.tree_leaf_icon
{
	color: #15638e;
	color: lighten(#15638e, 27%);
	
	z-index: 1;
	padding: 0px;
	margin: 0px;
	
	svg {
		padding: 0px;
		margin: 0px;
	}
}

.tree_node_children
{
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;
	
	margin-left: $tree_icon_size;
	
	padding: 4px 0;
	
	&.has_lower_siblings
	{
		// Vertical
		&::after
		{
			content: "";
			position: absolute;
			top: calc($tree_icon_size / 2);
			left: calc($tree_icon_size / 2 - 1px);
			border-left: 2px solid #ddd;
			border-radius: 0;
			width: $tree_icon_size + 2px;
			height: 100%;
			pointer-events: none;
		}
	}
}

.tree_label
{
	line-clamp: 1;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	
	width: 100%;
	
	padding-left: 6px;
	
	display: flex;
	flex-direction: row;
	
	align-items: center;
}

// .tree_leaf
// {
// 	background: #ccf3;
// }

// .tree_leaf:nth-child(2n)
// {
// 	background: #77f3;
// }

.vessel_stats {
	box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
	
	border-radius: 5px;
	padding: 10px;
}