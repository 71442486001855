.parts_table
{
	
}

.table_header
{
	width: 100%;
}


.header_cell
{
	padding-left: 15px;
	
	color: rgb(24, 111, 224);
}