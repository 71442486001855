.data_dashboard_container {
	display: flex;
	
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	
	width: 100%;
}


.dashboard_cards_grid {
	width: 100%;
	margin: 0;
	
	margin-bottom: 20px;
	margin-top: 40px;
	display: flex;
	
	justify-content: center;
	align-items: center;
}