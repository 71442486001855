.edit_collaborators
{
	@include sidebarStyles();
	
	
	position: absolute;
	top: 0px;
	
	padding-top: 4rem;
	
	border-left: 1px solid$color--frost;
	
	z-index: 2;
	
	
	.collaboratorsList
	{
		margin: 0 1rem;
	}
	
	
	.search 
	{
		margin-top: 4rem;
		
		input 
		{
			border: 1px solid #E4E4E4;
			border-radius: 5px;
			
			padding: 1rem;
			margin: 0 1rem;
			
			font-size: 1rem;
			
			height: 2rem;
			width: 100%;
			
			box-shadow: 0 2px 2px rgba(130, 130, 130, 0.25);
			
			transition: all .2s ease;
			
			&::placeholder
			{
				color: #7D7D7D;
			}
			
			&::-ms-input-placeholder
			{
				color: #7D7D7D;
			}
			
			&:-ms-input-placeholder
			{
				color: #7D7D7D;
			}
			
			&:focus
			{
				outline: none;
				box-shadow: 0 0 1px rgba(130, 130, 130, 0.25);
			}
		}
		
		
		&__inputs 
		{
			display: flex;
			align-items: center;
			
			position: relative;
		}
		
		&__icon 
		{
			position: absolute;
			
			right: 1rem;
			
			height: 1.8rem;
			width: 1.8rem;
			
			display: grid;
			place-items: center;
			
			color: #7D7D7D;
			background-color: $color--snow;
			
			svg
			{
				font-size: 1.5rem;
			}
		}
		
		&__dataResult 
		{
			margin-top: 5px;
			
			width: 100%;
			height: auto;
			
			overflow: hidden;
			overflow-y: auto;
			
			justify-content: stretch;
			align-items: stretch;
			
			cursor: pointer;
			
			&::-webkit-scrollbar
			{
					display: none;
			}
		}
		
		&__dataItem
		{
			border-radius: 5px;
			margin: 0 1rem;
			
			&--active
			{
				border: 1px solid #505050;
				
				&:hover
				{
					border: none;
				}
			}
			&:hover
			{
				border: 1px solid #505050;
			}
		}
	}
	
	#clearBtn
	{
		cursor: pointer;
	}
}