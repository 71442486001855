.systems_page
{
	width: 100%;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	
	justify-content: flex-start;
	align-items: center;
	
	overflow-y: auto;
}

.systems_grid
{
	background-color: inherit;
	width: 90%;
	height: 60%;
	
	margin-bottom: 200px;
}

.systems_page_search_section
{
	display: flex;
	
	justify-content: center;
	align-items: center;
	
	width: 90%;
}


.systems_asset_card_container
{
	background: #fff;
	
	width: 60%;
	
	padding: 5px;
	margin: 10px 0;
	border-radius: 3px;
	
	box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
	
	display: flex;
	flex-direction: column;
	
	align-items: flex-start;
	justify-content: space-between;
	
	cursor: pointer;
	
	&:hover
	{
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	}
	
	.icon
	{
		margin-left: 5px;
	}
}


// Grid cell css
.systems_grid_cell
{
	width: 100%;
	
	display: flex;
	flex-direction: column;
	
	align-items: flex-start;
	justify-content: center;
	
	cursor: pointer;
}


.systems_grid_cell_top_section
{
	width: inherit;
	
	display: flex;
	flex-direction: row;
	
	align-items: center;
	justify-content: space-between;
}


.systems_grid_cell_bottom_section
{
	width: inherit;
	
	display: flex;
	flex-direction: row;
	
	align-items: center;
	justify-content: flex-start;
}