



.fire_tables
{
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
}




.fire_table
{
	display: flex;
	flex-direction: column;
	justify-self: center;
	//align-self: center;
	flex-shrink: 1;
	
	width: fit-content;
	
	margin: 3px;
	padding: 3px;
	
	text-align: center;
	
	background: #d12326;
	
	transition: .4s;
	
	border-radius: 7px;
	
	box-shadow:
		0 4px 5px #0003,
		0 1px 2px 1px #0004;
	
	h3
	{
		padding: 6px;
		font-size: 26px;
	}
	
	th
	{
		padding: 6px 16px;
	}
	
	table
	{
		background: #fffd;
		
		font-size: 22px;
		
		border-radius: 5px;
	}
	
	td
	{
		background: white;
		border-radius: 2px;
	}
}



.fire_table__cell
{
	display: flex;
	justify-content: center;
	align-items: center;
	
	padding: 3px 6px;
		
	
	p
	{
		padding: 3px 6px;
	}
	
	.icon
	{
		cursor: pointer;
		
		color: #55e4ff;
		
		transition: .2s;
		
		user-select: none;
		
		&:hover
		{
			color: #6479ff;
		}
	}
}


