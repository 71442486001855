// Content_Headline.tsx
	.headline
	{
		display: flex;
		align-items: flex-end;

		margin-bottom: .3rem;

		&--username
		{
			margin-right: .3rem;
			font-size: .9rem;
		}

		&--dateTime
		{
			font-size: .9rem;
			font-weight: 300;

			color: $color--ash;
		}
	}
// -------------