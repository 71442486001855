

$selected_thread_bg: magenta;
$thread_bg: cornflowerblue;
$thread_list_bg: royalblue;


.messages_zone
{
	display: flex;
	flex-direction: column;
	
	background: $selected_thread_bg;
	
	border-radius: 0 11px 11px 0;
	
	& > h3
	{
		padding: 5px 12px;
	}
}



.messages_in_thread
{
	overflow: hidden;
	
	height: 400px;
	
	margin: 6px;
	border-radius: 5px;
	
	background: #fff;
	
	
	// Starts with, and contains the substring "react-scroll-to-bottom-" occurring
	// directly after a space character
	.button__go_to_end
	{
		right: 15px;
		
		border: solid #0005;
		border-width: 0 2px 2px 0;
		display: inline-block;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		
		background: #fff0;
		
		transition: $timing_quick;
		
		border-radius: 0;
		
		margin: 8px;
		padding: 3px;
		
		height: 13px;
		width:  13px;
		
		
		&:hover
		{
			border: solid #000;
			border-width: 0 3px 3px 0;
			
			background: #fff0;
		}
	}
}



.message_area
{
	display: flex;
	flex-direction: column;
	flex-shrink: 1;
	
	//width: fit-content;
	
	padding: 2px;
	margin: 3px 8px;
	margin-right: 24px;
	border-radius: 5px;
}

.message
{
	display: flex;
	flex-direction: column;
	flex-shrink: 1;
	
	width: fit-content;
	
	padding: 8px;
	margin: 0px;
	border-radius: 5px;
	
	background: #fffa;
}

.message__content
{
	//background: #fff;
	font-size: 14px;
}

.message__sender
{
	//text-align: flex-end;
	font-size: 10px;
	color: #0007;
}

.message__sent_at
{
	text-align: flex-end;
	font-size: 12px;
	color: #0003;
}




.messages_page
{
	display: flex;
	flex-direction: column;
	
	padding: 32px;
}


.messages_ui
{
	display: flex;
	flex-direction: row;
}


.message_thread_list
{
	display: flex;
	flex-direction: column;
	
	background: $thread_list_bg;
	
	border-radius: 11px 0 0 11px;
	
	& > h2
	{
		padding: 8px;
		margin: 5px;
		line-height: 1;
		font-size: 20px;
	}
}


.message_thread_item
{
	display: flex;
	flex-direction: column;
	
	padding: 8px;
	margin: 5px 0 5px 5px;
	
	background: $thread_bg;
	
	border-radius: 11px 0 0 11px;
	
	cursor: pointer;
	transition: $timing_tabs;
	font-weight: 500;
	
	user-select: none;
	
	
	&:hover
	{
		background: lighten($thread_bg, 10%);
	}
	
	&.active
	{
		background: $selected_thread_bg;
	}
	
	&.active:hover
	{
		background: $selected_thread_bg;;
	}
}



.message_composition
{
	display: flex;
	flex-direction: column;
	
	& > textarea
	{
		margin: 5px;
	}
}

.message_sender_info
{
	display: flex;
	flex-direction: column;
	align-items: center;
}


.message_thread_message_count
{
	margin-left: 15px;
	color: #0005;
}

