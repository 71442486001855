// TODO: avoid specifying colors directly - prefer to reference variables



.nav_background
{
	position: relative;
	
	grid-area: bottom_left;
	
	background: #ffffff;
	
	padding: 100px 0 10px 0;
	
	
	&::after
	{
		@include absolute();
		
		z-index: 6000;
		pointer-events: none;
		
		box-shadow: inset -4px 0px 4px -3px #0008, inset -2px 0px 2px -1px #0008;
	}
}


// Nav bar (Drawer) Styling









// Left pane
.nav_bar
{
	position: relative;
	display: flex;
	justify-content: flex-start;
	z-index: 100001;
	display: flex;
	flex-direction: column;
	padding: 10px 0 10px 6px;
	background: #ffffff;
	color: #2d3643;
	
	
	.MuiDrawer-paper
	{
		background-color: #2d3643;
		color: #ffffff;
		
	}
	
	svg
	{
		color: #ffffff;
	}
}



// .nav_item_wrapper {
//   position: relative;

//   z-index: 9999;

//   filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.6))
//     drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));

//   &:hover {
//     filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.8))
//       drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.7));
//   }
// }


.nav_top
{
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}


.nav_body_top
{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	
	height: 70%;
}


.nav_body_bottom
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	position: absolute;
	bottom: 0;
	left: 0;
	
	width: 100%;
	
	padding: 0;
}



.nav_list_header
{
	display: flex;
	flex-direction: row;
	justify-content: center;
	transition: all 0.2s ease;
	
	max-width: 180px;
	
	padding-top: 10px;
	
	margin-top: 10px;
	
	
	&:hover
	{
		background-color: #34445c;
		color: #ffffff;
		
		border-radius: 8px;
		
		h2
		{
			color: #ffffff;
		}
		
		svg
		{
			color: #ffffff;
		}
	}
	
	&.active
	{
		background-color: #2d3643;
		color: #1982c4;
		
		border-radius: 8px;
		
		h2
		{
			color: #1982c4;
		}
		
		svg
		{
			color: #1982c4;
		}
	}
}



.nav_list
{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	transition: all 0.2s ease;
	
	max-width: 180px;
	
	padding-top: 10px;
	
	margin-top: 10px;
	
	
	
	&:hover
	{
		background-color: #34445c;
		color: #ffffff;
		
		border-radius: 8px;
		
		h2
		{
			color: #ffffff;
		}
		
		svg
		{
			color: #ffffff;
		}
	}
	
	&.active
	{
		background-color: #2d3643;
		color: #1982c4;
		
		border-radius: 8px;
		
		h2
		{
			color: #1982c4;
		}
		
		svg
		{
			color: #1982c4;
		}
	}
}



.nav_item
{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-self: center;
	
	width: 100%;
	
	padding-right: 15px;
	
	color: inherit;
	text-decoration: none;
	
	z-index: 9999;
	
	svg
	{
		color: #ffffff;
	}
}



.nav_logo
{
	width: 3rem;
	height: 3rem;
	
	border-radius: 3px;
	margin-left: 0.5rem;
	
	position: absolute;
	left:0;
}



.nav_prof_position
{
	background-color: #1982c4;
	border-radius: 3px;
	padding-left: 8px;
}


// html {
// 	overflow: hidden;
// 	width: 100%;
// }

// body {
// height: 100%;
// position: fixed;
// overflow-y: scroll;
// -webkit-overflow-scrolling: touch;
// }




.app_bar
{
	grid-area: header;
	
	// TODO: Why fixed? That breaks the grid system
	// position: fixed;
	// left: 0;
	// top: 0;
	
	z-index: 10000;
	
	display: flex;
	
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;
	
	width: unset;
	flex-grow: 1;
	
	border-radius: 0;
	
	
	// box-shadow: none;
	background: $color_misty;
	
	box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
	
	// height: $app_bar_height;
	
	
	.icon
	{
		color: $color_text_primary_dark
	}
	
	h4
	{
		color: $color_text_primary_dark;
	}
}



.scanner_button
{
	margin-right: 15px;
	
	color: #393a42;
	border-color: #393a42;
	
	transition: $timing_quick;
	
	&:hover
	{
		background-color: #e2e0e0;
		border-color: #393a42;
	}
	
	&:active
	{
		transform: scale(1.02);
	}
}



// TODO: where is this used?
#CONTAINER_DIV_ID
{
	display: flex;
	align-self: center;
	
	padding: 8px;
	margin-right: 12px;
	
	
	span
	{
		font-size: 1.5em;
		
		transition: $timing_quick;
	}
	
	&:hover
	{
		span
		{
			color: #1982c4;
		}
	}
}



// Mobile view
.bottom_nav_container
{
	grid-area: nav;
	
	// position: fixed;
	// bottom: 0;
	// left: 0;
	// right: 0;
	
	z-index: 10000;
	// height: 50px;
}



.bottom_navigation_bar
{
	background-color: #2d3643;
	
	display: flex;
	flex-direction: row;
	
	justify-content: space-around;
	align-items: center;
	width: 100%;
	
	height: 50px;
	
	
	.icon
	{
		// color: $color_text_primary_dark
	}
}



.bottom_navigation_buttons
{
	// padding: 0px;
	
	max-width: none;
	min-width: fit-content;
	
	// color: #fff;
	
	// transition: none;
	// transform: none;
	
	
	// span
	// {
	// 	// font-size: 12px;
	// 	color: #eee;
	// }
	
	// span:active
	// {
	// 	// padding: 0px;
	// 	color: #ddd;
	// }
}



.css-1pzf1qu-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected
{
	padding: 0px;
	
	span
	{
		// font-size: 12px;
		color: #fff;
	}
	
	svg
	{
		color: #fff;
	}
}



.nav_add_action_icon
{
	width: 30px;
	height: 30px;
	
	&:active
	{
		transform: scale(1.15);
		transition: 0.4s ease;
	}
}



.mobile_nav_drawer
{
	z-index: 900000;
	
	.MuiDrawer-paper
	{
		background: $color--bg-light;
		border-radius: 16px 16px 0 0;
		margin-top: 40px;
	}
	
	span
	{
		margin-left: 8px;
		font-size: 14px;
		color: $color_text_primary_dark;
	}
}



.mobile_nav_drawer_header
{
	width: 100%;
	padding: 10px 5px;
	
	
	display: flex;
	
	align-items: center;
	justify-content: space-between;
	
	
	h3
	{
		margin-left: 10px;
		font-weight: 600;
		
		color: '#323338';
	}
}



.mobile_nav_icons
{
	// color: $color_text_primary_dark;
	
	padding: 3px;
	border-radius: 4px;
}


