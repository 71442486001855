
.stroke_wrapper
{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	
	.stroke
	{
		padding: 0 .15em;
	}
	
	.whitespace
	{
		white-space: nowrap;
		width: .3em;
	}
}


//------------------------------------------------------------------------------
// This applies a stroke to text. This requires a mixin because the standard CSS
// definition of outline is actually a midline - the stroking marker is centered
// on the edge of the characters, which means that the "outline" goes inside the
// letterform and corrupts its shape.
// 
// This adds a lower-z-level 'before' pseudoelement that is stroked and
// shadowed. The actual text then covers up the inner outline bullshit, thereby
// preserving the original character's edges.
// 
// NOTE: The text element must have its text in a 'data-text' attribute so the
//       pseudoelement knows what to use as its content.
//------------------------------------------------------------------------------
@mixin stroke($color: black)
{
	position: relative;
	
	z-index: 1;
	
	
	&::before
	{
		position: absolute;
		
		z-index: -1;
		
		content: attr(data-text);
		
		-webkit-text-stroke: 2px $color;
		
		text-shadow:
			
			// diagonals
			 1px  1px 1px rgba($color, .7 * alpha($color)),
			 1px -1px 1px rgba($color, .7 * alpha($color)),
			-1px -1px 1px rgba($color, .7 * alpha($color)),
			-1px  1px 1px rgba($color, .7 * alpha($color)),
			
			// orthagonals
			 0px  1px 1px rgba($color, .7 * alpha($color)),
			 1px  0px 1px rgba($color, .7 * alpha($color)),
			 0px -1px 1px rgba($color, .7 * alpha($color)),
			-1px  0px 1px rgba($color, .7 * alpha($color)),
			
			// omnidirectional and downward
			0 0px 2px $color,
			0 1px 2px rgba($color, .5 * alpha($color)),
			0 2px 1px rgba($color, .1 * alpha($color))
		;
	}
}

// EFFECTS
.stroke
{
	@include stroke();
	color: white;
}

.light_stroke
{
	@include stroke(#0002);
	color: white;
}

.stroke_on_hover:hover
{
	@include stroke();
}

*:hover > .stroke_on_parent_hover
{
	@include stroke();
}



// This is most commonly used for a before/after pseudoelement to ensure
// that it occupies the same space as the element. This is useful for shadows,
// stroking text, etc.
@mixin absolute()
{
	position: absolute;
	
	content: '';
	
	top:    0;
	right:  0;
	bottom: 0;
	left:   0;
}

