


.badges
{
	&__wrapper
	{
		
		display: flex;
		flex-direction: row;

		align-items: center;
		justify-content: center;
		
		padding: 5px 10px;
		border-radius: $br--round;
		color: $color--cloud;

		cursor: pointer;
		
		width: fit-content;

		// disable text highlighting
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
				user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */

	}

	&__icon
	{
		margin-left: .3rem;
		margin-top: 3px;
	}

	&--eggplant
	{
		background-color: $color--eggplant;
	}

	&--ocean
	{
		background-color: $color--ocean;
	}

	&--spring
	{
		background-color: $color--spring;
	}

	&--sunflower
	{
		background-color: $color--sunflower;
	}

	&--blush
	{
		background-color: $color--blush;
	}

	&--jade
	{
		background-color: $color--jade;
	}

	&--mustard
	{
		background-color: $color--mustard;
	}

	&--smoke
	{
		background-color: $color--smoke;
		color: $color--snow;
	}

	&--abyss
	{
		background-color: $color--abyss;
		color: $color--snow;
	}

	&--ash
	{
		background-color: $color--ash;
		color: $color--snow;
	}

	&--tag
	{

		outline: 1px solid $color--smoke;
		padding: 0px 18px;
		color: $color--charcoal;
	}

	&--rectangle
	{
		border-radius: $br--small;
	}

	&--selected
	{
		outline: 2px solid $color--sunflower;
	}
}


