
.basic_card
{
	max-width: 100%;
	// min-height: 150px;
	
	background: $color_pitch_light;
	border-radius: 7px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
	
	display: flex;
	// flex-direction: column;
	justify-content: flex-start;
}



.container
{
	max-width: 100%;
	min-height: 150px;
	
	background: $color_pitch_light;
	padding: 20px;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
	
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}


.email_zone
{
	display: flex;
	min-height: inherit;
	
	justify-content: space-around;
}

.email_button
{
	max-width: 150px;
}


.chip_zone
{
	margin: 5px 5px 5px 5px;
}



.alert
{
	z-index: 10000000;
	
	position: absolute;
	
	bottom: 5%;
	left: 45%;
	
	width: 300px;
	height: 50px;
}


// Styling for the User List Table
.user_table
{
	width: 100%;
	margin-top: 20px;
}


.table
{
	display: flex;
	flex-direction: column;
	
	justify-content: center;
	align-items: center;
}



.maintain_data_page_overlay {
	display: flex;
	
	min-height: 500px;
	
	flex-direction: column;
	justify-content: space-between;
}


.tags_page {
	display: flex;
	width: 100%;
	
	justify-content: space-around;
}


.create_tags_form {
	display: flex;
	flex-direction: column;
	
	justify-content: space-between;
	align-items: flex-start;
	
	width: fit-content;
	
	padding: 20px;
	
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	
	
	border-radius: 5px;
}


.tag_picker {
	display: flex;
	
	justify-content: space-between;
	align-items: center;
	padding-bottom: 10px;
}

.tag_picker_form_label {
	border-radius: 5px;
	color: #fff;
	padding: 5px;
	
	font-size: 13px;
	font-weight: 500;
}

.create_tags_header {
	color: rgb(83, 82, 82);
	padding-bottom: 10px;
}

.create_tags_fields {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	
	padding-bottom: 10px;
	width: 100%;
}


.select_tags_button {

	padding: 4px;
	
	border-radius: 2px;
	
	box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
	rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}


// Component Selection Panel Styling
.issue_details_panel_header {
	margin-left: 10px;
	padding: 5px;
	
	color: rgb(71, 71, 71);
	
	box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}


.flagged_component_title {
	padding: 5px;
	margin-bottom: 10px;
	
	border-radius: 4px;
	
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, 
				rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	
	display: flex;
	align-items: center;
	justify-content: center;
}

.warning_icon {
	color: rgb(223, 192, 54);
}

.issue_detail_panel {
	display: flex;
	
	flex-direction: column;
	
	justify-content: space-around;
	align-items: flex-start;
	
	
	height: 70%;
}