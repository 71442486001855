


.map_page
{
	height: 100%;
	
	display: flex;
	flex-direction: row;
	
	padding: 40px;
	overflow: hidden;
	
	
	dl {
		display: grid;
		grid-template: auto / 10em 1fr;
	}
	
	dt {
		background: #fee;
	}
	
	dd {
		background: hsl(220, 10%, 95%);
	}
	
	dt, dd {
		margin: 0;
		padding: .3em .5em;
		border-top: 1px solid rgba(0,0,0,.1);
	}
}



.map_control_panel_section
{
	margin-right: 30px;
	
	width: 60%;
	height: 100%;
	
	overflow-y: scroll;
	padding: 20px;
}



.leaflet-container
{
	width: 100%;
	height: 100%;
}


.trip_analysis_card
{
	margin-bottom: 20px;
	padding: 10px;
	
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	border-radius: 5px;
}

.trip_summary_section
{
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
}


.trip_performance_section
{
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-wrap: wrap;
	
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 10px;
}


.trip_weather_section
{
	display: flex;
	width: 100%;
	
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
}

.trip_card_sections
{
	margin-bottom: 20px;
	width: 100%;
}

.trip_card_weather
{
	display: flex;
	flex-direction: column;
	padding: 10px;
	width: 100%;
	
}

.trip_data
{
	display: flex;
	padding: 5px;
	
	justify-content: center;
	align-items: center;
	
	font-weight: 450;
	font-size: 12px;
	
	color: rgb(85, 85, 85);
	
	p {
		font-weight: 500;
		font-size: 13px;
		
		color: rgb(36, 35, 35);
		margin-left: 5px;
	}
}

.trip_card_section_headers
{
	padding: 8px;
	
	color: #fff;
	
	border-radius: 4px;
	font-size: 16px;
	font-weight: 500;
}

.co2_icon
{
	font-size: 32px;
	margin-right: 5px;
	
	color: rgb(240, 84, 49);
}

.fuel_icon
{
	font-size: 20px;
	margin-right: 12px;
	margin-left: 5px;
	
	color: rgb(233, 165, 56);
}

.cost_icon
{
	font-size: 20px;
	margin-right: 13px;
	margin-left: 4px;
	
	color: rgb(56, 196, 91);
}

.timer_icon
{
	font-size: 20px;
	margin-right: 13px;
	margin-left: 4px;
	
	color: rgb(80, 177, 225);
}

.trips_card_section
{
	height: 100%;
}

.route_optimization_demo
{
	color: rgb(72, 72, 72);
	border-bottom: 1px solid rgb(210, 210, 210);
	
	padding-bottom: 5px;
	
	margin-bottom: 20px;
	font-size: 18px;
	font-weight: bold;
}