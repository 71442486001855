

.documents
{
	@include sidebarStyles();
	
	
	position: absolute;
	z-index: 2;
	top: 0px;
	
	border-left: 1px solid$color--frost;
	
	
	&__fileList
	{
		margin: 0 1rem;
		
		> div
		{
			margin: 1rem 0;
		}
	}
}

