.input_zone {
	display: flex;

	margin: 6px;
	padding: 12px;

	background: #0002;
	border-radius: 12px;

	textarea {
		flex-grow: 1;
	}
}


.dashboard_ceto
{
	display: grid;
	grid-template-areas:
		'nav header'
		'nav main';
	grid-template-columns: auto minmax(0, 1fr);
	grid-template-rows: auto minmax(0, 1fr);
	
	width: 100vw;
	height: 100vh;
	
	overflow: hidden;
	
	
	@media only screen and (max-width: 599px)
	{
		grid-template-areas:
			'header'
			'main'
			'nav';
		grid-template-columns: auto;
		grid-template-rows: 50px auto 50px;
	}
}

.main_content {
	//padding-top: $app_bar_height;
}

// .header
// {
// 	z-index: 1;

// 	padding: 6px;
// 	font-size: 24px;
// 	font-weight: 600;
// 	background: #5e84ff;

// 	box-shadow:
// 		0 1px 2px 1px #0003;
// }

.header {
	display: contents;
}

.header_shadow {
	grid-row: 1;
	grid-column: 1 / 3;
	z-index: 20000;

	pointer-events: none;

	box-shadow: 0 1px 3px 0 #000a;
	border-bottom: 1px solid #000a;
}

// Within right pane
.header_content {
	grid-area: top_right;
	
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	z-index: 10000;

	padding: 8px 16px;
	background: $header_bg;
	color: white;

	h1 {
		@include stroke(#0008);
	}
}

.logo {
	grid-area: top_left;
	z-index: 10000;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background: $header_bg;

	color: cyan;
}

main {
	grid-area: main;

	//   align-items: flex-start;

	z-index: 1;
	display: flex;
	flex-direction: column;
	overflow-y: auto;

	height: 100%;
	width: 100%;

	article {
		width: 100%;
		padding: 24px;
	}

	background: $color_foggy;
}

.demo_page {
	display: flex;
	flex-direction: column;

	padding: 32px;
}
