


.pairs_zone
{
	display: flex;
	flex-direction: column;
	align-items: center;
	
	margin: 4px;
}




.pairs
{
	display: grid;
	
	grid-template-columns: auto auto;
	
	margin-block-start:  0px;
	margin-block-end:    0px;
	
	
	.pair
	{
		display: contents;
		
		.pairs__value__content
		{
			transition: .3s;
		}
		
		&:hover
		{
			dt
			{
				color: $color_secondary_dark;
			}
			.pairs__value__content
			{
				color: #000;
				background: white;
				box-shadow:
					0 .4px 1px 1px #0004,
					0 .4px 3px 1px #0003;
			}
		}
	}
	
	dt,
	dd
	{
		display: flex;
		flex-direction: row;
		
		margin: 1px 0;
		
		transition: .25s;
		
		color: darken($color_primary_main, 8%);
	}
	
	dt,
	dd .pairs__value__content
	{
		padding: 2px 6px;
		
		border-radius: 5px;
	}
	
	dd
	{
		align-items: center;
	}
	
	dt
	{
		justify-content: flex-end;
		text-align: end;
		
		padding-right: 8px;
		margin-right: 1px;
		
		font-weight: 500;
		
		color: lighten($color_secondary_main, 11%);
	}
}

