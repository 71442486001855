


.button
{
	//@include button();
	
	$border_color: orange;
	$border_color_hover: red;
	$background_color: khaki;
	
	
	display: flex;
	align-items: center;
	justify-content: center;
	
	border: 1px solid $border_color;

	background: $background_color;
	
	padding: 6px 12px;
	margin: 5px;
	
	border-radius: 5px;

	font-size: 16px;
	font-weight: 500;
	letter-spacing: 1.3px;
	
	cursor: pointer;
	
	transition: $timing_tabs;
	
	
	.icon
	{
		margin-right: 5px;
	}
	
	
	
	box-shadow:
		inset 0 0 1px $border_color_hover,
		0 0px 2px 0 #000c,
		0 1px 3px 0 #000a;
	
	
	&:not([disabled])
	{
		&:hover
		{
			background: moccasin;
			border: 1px solid $border_color_hover;
			
			box-shadow:
				inset 0 0 1px $border_color_hover,
				inset 0 0 2px $border_color_hover,
				0px 0px 3px $border_color_hover,
				0 0px 2px 0 #000c,
				0 1px 4px 0 #0007,
				0 2px 4px 0 #0006,
				0 2px 5px 1px #0003;
		}
		
		&:focus,
		&:active
		{
			border: 1px solid $border_color_hover;
			//border-radius: 5px;
			outline: none;
			box-shadow:
				inset 0 0 1px $border_color_hover,
				inset 0 0 2px $border_color_hover,
				0px 0px 6px $border_color_hover,
				0 0px 2px 0 #000c,
				0 1px 4px 0 #0007,
				0 2px 4px 0 #0006,
				0 2px 5px 1px #0003;
		}
	}
	
	
	&:disabled
	{
		$border_color: black;
		
		border: 1px solid #0003 !important;
		
		background: #888;
		color: #aaa;
		
		cursor: not-allowed;
		
		box-shadow:
			inset 0 0 1px #000c,
			0 0px 2px 0 #000c,
			0 1px 3px 0 #000a;
	}
}
