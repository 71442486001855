

.issueTitle
{
	padding: 1.5rem;
	border-bottom: 1px solid $color--smoke;
	background-color: $color--frost;
	
	position: sticky;
	z-index: 1;
	top: 0;
	
	&__tracking
	{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		
		&--issue
		{
			border-radius: $br--small;
			
			padding: 3px 10px;
			margin-right: 1rem;
			
			background-color: $color--abyss;
			color: $color--cloud;
			
			font-size: 1.5rem;
			font-weight: 700;
		}
	}
	
	&__headline
	{
		color: $color--charcoal;
		
		margin-top: 1rem;
		
		font-size: 1.2rem;
	}
	
	&__badges
	{
		display: flex;
		flex-wrap: wrap;
		
		margin: .5rem 0;
		
		> div {
			margin-right: .5rem;
		}
	}
	
	&__details
	{
		color: $color--ash;
	}
}

