


.chat_content_zone
{
	display: flex;
	
	padding: 10px;
	padding-left: 16px;
	
	
	&:hover
	{
		h5
		{
			color: #000;
		}
		.chat_content_info__date
		{
			color: #000c;
		}
	}
}


.chat_content
{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	
	padding: 0 12px;
}




.chat_content_files,
.chat_content_message
{
	margin-top: 4px;
	margin-left: 12px;
	padding: 5px 12px;
	
	font-size: 15px;
	
	background: #fff;
	
	border-radius: 7px;
	// box-shadow:
	// 	0 0 1px #000a,
	// 	0 1px 4px #0007;
	
	box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
	
	white-space: pre-wrap;
}



.chat_content_files
{
	// margin-left: 24px;
	padding: 0px;
	// background: #fff;
}



.chat_content_summary
{
	margin-left: 2px;
	padding: 5px 12px;
	
	font-size: 14px;
	
	color: #000e;
}



.chat_content_info
{
	display: flex;
	flex-direction: row;
	align-items: center;
	
	h5
	{
		//padding: 6px;
		font-size: 13px;
		font-weight: 400;
		color: #0005;
		transition: $timing_quick;
	}
}



.chat_content_info__date
{
	margin-left: 22px;
	
	font-size: 13px;
	font-weight: 400;
	
	color: #0004;
}


.chat_event_summary {
	color: rgb(92, 92, 92);
	font-weight: 450;
}


.chat_event_collaborators
{
	display: flex;
	flex-direction: column;
	
	justify-content: center;
	align-items: flex-start;
	
	padding-left: 14px;
	
	color: rgb(133, 132, 132);
	font-size: 12px;
	font-weight: 400;
	
	padding: 5px 10px;
}

