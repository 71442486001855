.issueDetailsMain {
  height: "75vh";
  & > MuiTextField-root {
    margin: 1;
    min-width: "25ch";
  }
  & > MuiInputBase-root {
    margin: 1;
    min-width: "25ch";
  }
}

.boxRow {
  display: "flex";
  flex-direction: "row";
  align-items: "center";
}

.boxCol {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
}



.component_tag {
  margin: 0.5rem 0;
  margin: 1rem 1rem;
  padding: 2px;
  color: $color--ash;

  border-radius: $br--medium;
  border: 1px solid $color--smoke;
}

.component_list {
  
  margin-top: 20px;
  
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;
  justify-content: flex-start;
}



.stepperContainer {
  display: "flex";
  flex-direction: "row";
  align-items: "center";

  margin: 1rem;
}

.specify_components_container {
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: row;
}

.issue_name_header_box {
  display: flex;
  
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.issue_name_text_field {
  margin-left: 20px;
  
  min-width: 300px;
}


.issue_detail_panel {
  display: flex;
  
  flex-direction: column;
  
  justify-content: space-between;
  align-items: flex-start;
  
  padding-left: 10px;
  padding-right: 10px;
  
  height: 50%;
}

.drawer_components {
  min-height:70%;
  width: 100%;
  
  height: fit-content;
  
  margin-bottom: 20px;
  
  padding: 10px;
  
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
              rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
