.calendar_box {
	width: 50%;
}

.planItem {
	margin: "1rem 0";
	width: 100%;
}

.inputSpan {
	margin-top: 1rem;
}


.collaborators_list {
	margin-top: 10px;
	
	display: flex;
	flex-direction: column;
	
	justify-content: flex-start;
	align-items: center;
}


.collaborator_card {
	padding: 4px;
	margin-top: 8px;
	border-radius: 4px;
	display: flex;
	
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, 
				rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	justify-content: flex-start;
	align-items: center;
	
	width: fit-content;
	min-width: 250px;
}

.collaborator_info {
	padding: 5px;
	
	display: flex;
	flex-direction: row;
	
	justify-content: center;
	align-items: center;
}

.collaborator_name {
	font-size: 14px;
	font-weight: 500;
	color: rgb(77, 77, 77);
}

.collaborator_role {
	
	margin-left: 4px;
	padding: 4px;
	
	background-color: rgb(46, 62, 80);
	color: #fff;
	
	border-radius: 4px;
	font-weight: 400;
	font-size: 12px;
}

.attach_documents_header {
	box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
	
	font-weight: 500;
	color: rgb(37, 37, 37);
	
	font-size: 18px;
	margin-bottom: 10px;
}

.stepper_control {
	margin-bottom: 5px;
}

.issue_form_error {
	color: rgb(212, 26, 26);
}






.attach_documents_file_list
{
	display: flex;
	flex-direction: column;
	
	padding: 16px;
}


.attach_documents_file_list_item
{
	display: flex;
	flex-direction: row;
	
	padding: 5px;
	
	
	.icon
	{
		margin-right: 6px;
		font-size: 16px;
	
		color: #0004;
	}
	
}






