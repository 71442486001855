

.addCollab__container 
{
	display: flex;
	
	line-height: 1rem;
	font-size: .9rem;
	
	color: $color--ash;
}

