.file
{    
	display: flex;
	justify-content: space-around;
	align-items: center;

	border: 2px solid $color--blush;
	border-radius: $br--medium;
	padding: .5rem 1rem;

	color: $color--blush;
	background-color: $color--snow;

	cursor: pointer;

}