
@import './chat_styles/chat';

@import './sidebar_styles/sidebar';




.search
{
	input
	{
		border: 1px solid #E4E4E4;
		border-radius: 5px;
	
		padding: 1rem;
		margin: 0 1rem;
		
		font-size: 1rem;
	
		height: 2rem;
		width: 100%;
	
		box-shadow: 0 2px 2px rgba(130, 130, 130, 0.25);
	
		transition: all .2s ease;

		&::placeholder {
			color: #7D7D7D;
		}

		&::-ms-input-placeholder {
			color: #7D7D7D;
		}

		&:-ms-input-placeholder {
			color: #7D7D7D;
		}

		&:focus {
			outline: none;
			box-shadow: 0 0 1px rgba(130, 130, 130, 0.25);
		}
	}
	

	&__inputs 
	{
			display: flex;
			align-items: center;
	
			position: relative;
	}

	&__icon 
	{
		position: absolute;
		
		right: 1rem;
		
		height: 1.8rem;
		width: 1.8rem;
		
		display: grid;
		place-items: center;
		
		color: #7D7D7D;
		background-color: $color--snow;
		
		svg
		{
			font-size: 1.5rem;
		}
	}
}


.communication
{
	display: flex;
	
	width: 100%;
	
	overflow-y: auto;
}


.username-highlist
{
	font-weight: 500;
	color: #1A7DB5;
	background: none;
}


.btn--green 
{
	@include btn( $color--jade );
}


.editor
{
	@include mentionTextEditorStyle(6rem);
}

