.userCard
{
	&__wrapper
	{
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		width: 100%;

		font-size: .8rem;

		outline: 1px solid $color--smoke;
		border-radius: $br--small;
		padding: .3em .2rem;
		
		color: $color--ash;
	}

	&__avatar
	{
		width: 2rem;
		height: 2rem;
	}

	&__position
	{
		font-size: .7rem;
		padding: .3rem 1rem;
		border-radius: $br--small;
		color: $color--cloud;
		
	}

	.position--technician {
		background: $color--mustard;
	}
	
	.position--generalManager {
		background: $color--blush;
	}
	
	.position--systemEnginer {
		background: $color--eggplant;
	}

	.position--juniorEnginer {
		background: $color--abyss;
	}
}