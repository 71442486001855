@import './nested_Contents';


.nestedItem
{
	display: flex;
	align-items: center;
	// justify-content: space-between;
	
	color: $color--charcoal;
	
	border-radius: $br--small;
	
	margin: 0 1rem;
	padding: .8rem .5rem;
	
	cursor: pointer;
	
	// transition: all .3s ease;
	
	&__iconContainer
	{
		display: flex;
		justify-content: center;
		align-items: center;
		
		width: 2rem;
		height: 2rem;
		
		border-radius: $br--small;
	}
	
	&__title
	{
		margin: 0 0 0 .5rem;
		font-size: .9rem;
	}
	
	&--jade
	{
		background-color: rgba(61, 166, 84, 0.05);
	}
	&--ocean
	{
		background-color: rgba(25, 130, 196, 0.05);
	}
	&--eggplant
	{
		background-color: rgba(106, 76, 147, 0.05);
	}
	&--blush
	{
		background-color: rgba(194, 111, 111, 0.05);
	}
	
	
	&:hover
	{
		background-color: $color--frost;
	}
	
	.iconRight
	{
		margin-left: auto;
	}
}
