


.issue_sidebar
{
	display: flex;
	flex-direction: column;
	
	min-width: 300px;
	
	border-left: 1px solid #0002;
	
	.MuiDrawer-paper
	{
		color: #000e;
		background: #fff;
	}
	
	.MuiDivider-fullWidth
	{
		z-index: 3;
	}
	
	.icon
	{
		font-size: 1.6rem;
	}
	
	.minor
	{
		color: #0005;
	}
}



time
{
	.minor
	{
		font-weight: 300;
	}
}



.issue_reported_on_date
{
	white-space: pre-wrap;
	
	.minor
	{
		font-weight: 300;
		color: #aaa;
	}
}



.issue_sidebar_top
{
	display: flex;
	flex-direction: column;
	align-items: center;
	
	padding: 22px;
	margin-top: 14px;
	
	z-index: 2;
	
	background: #fff;
	
	h4
	{
		font-size: 18px;
		font-weight: 500;
	}
	
	.issue_sidebar_date
	{
		margin-top: 8px;
		color: rgb(26, 26, 26);
		
		display: flex;
		flex-direction: column;
		
		justify-content: center;
		align-items: center;
		
		p
		{
			font-size: 15px;
			font-weight: 400;
		}
	}
}

.issue_reported_on_date {
	padding: 8px 12px;
	border-radius: 4px;
	
	font-size: 15px;
	font-weight: 500;
	
	background: #1982c4;
	color: #fff;
}



.issue_sidebar_bottom
{
	//padding: 8px;
	
	overflow-y: auto;
	
	
	& > *
	{
		//padding: 6px 20px;
	}
	
	.MuiAccordion-root
	{
		box-shadow: unset;
		
		&::before
		{
			background: unset;
		}
	}
	
	.MuiAccordionSummary-root
	{
		padding: 0;
		margin: 0;
	}
	
	.MuiAccordionSummary-content
	{
		padding: 0;
		margin: 0;
	}
	
	.MuiAccordionDetails-root
	{
		padding: 0 12px 4px 12px;
	}
	
	.MuiAccordionSummary-expandIconWrapper
	{
		position: absolute;
		right: 8px;
		pointer-events: none;
	}
	
	.MuiPaper-root.Mui-expanded
	{
		margin: 0;
		min-height: unset;
	}
	.Mui-expanded
	{
		min-height: unset;
		
		.MuiAccordionDetails-root
		{
			margin-top: 4px;
			margin-bottom: 12px;
		}
	}
}



.chat_collaborators
{
	display: flex;
	justify-content: center;
	
	padding-top: 0;
}



.issue_event
{
	time
	{
		font-size: 14px;
	}
}


