@import './contents/content_IssueTitle';

@import './chatLog';

@import './InputForm';



.communication__chat
{
	color: $color--charcoal;
	background-color: $color--frost;
	
	width: 100%;
	min-height: 90vh;
	
	margin: 1.5rem 4rem 1.5rem 0;
	
	border: 1px solid $color--smoke;
	border-radius: $br--large;
	
	display: flex;
	flex-direction: column;
	
	overflow-y: auto;

	transition: all .5s ease;

	&::-webkit-scrollbar {
		display: none;
	}
}