.select_parts_container
{
	margin: 20px 0;
}

.select_parts_text
{
	padding: 8px;
	
	border: 1px solid rgb(195, 195, 195);
	border-radius: 4px;
	
	margin-bottom: 5px;
}

.selectable_parts_table
{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: fit-content;
	height: fit-content;
	
	max-width: 100%;
	
	box-shadow:  0px 2px 6px -1px rgba(0,0,0,.12);
	border-radius: 10px;
}

.parts_table_toolbar
{
	background: rgb(231, 231, 231);
	
	display: flex;
	
	justify-content: space-between;
	align-items: center;
	padding: 10px 14px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}

.parts_table_header
{
	display: flex;
	
	flex-direction: column;
}

.parts_selected
{
	padding: 4px;
	
	border-radius: 4px;
	background: #959595;
	color: #fff;
}

.parts_card_container
{
	margin-top: 20px;
	display: flex;
	
	justify-content: space-between;
	
	border-radius: 5px;
	
	background-color: #E9E9E9;
}

.parts_card_part_info
{
	display: flex;
	flex-direction: column;
	
	width: 70%;
	
	
	align-items: flex-start;
	justify-content: center;
	padding: 10px;
}

.parts_card_part_name
{
	font-weight: 500;
	font-size: 13px;
	
	margin-bottom: 5px;
}

.parts_card_manufacturer_name
{
	font-weight: 500;
	font-size: 13px;
	
	color: #3a3a3a;
}


.parts_card_qty_info
{
	display: flex;
	flex-direction: column;
	
	
	align-items: center;
	justify-content: space-around;
	background: #F2F2F2;
	
	padding: 10px;
	
	width: 30%;
	height: inherit;
	margin: 0;
	
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.parts_card_qty
{
	font-weight: 500;
	font-size: 13px;
	
	color: #3a3a3a;
}

.parts_card_qty_number
{
	font-weight: 500;
	font-size: 15px;
}

.tag_selection_container
{
	margin: 20px 0;
}







// Fillable form fields

.form_field_description
{
	background: $color_foggy;
	border-radius: 2px;
	padding: 10px;
}