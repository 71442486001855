




.file_list
{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	
	overflow-x: auto;
	overflow-y: auto;
	
	//overflow: hidden;
	//max-width: calc(100% - 60px);
	
	padding: 5px; // 2px;
	
	font-size: 13px;
	
	color: #0008;
	
	.MuiList-root
	{
		padding: 0;
	}
}



// TODO: Duplicate
.file_list_item
{
	display: flex;
	flex-direction: row;
	//justify-content: center;
	
	cursor: pointer;
	user-select: none;
	
	padding: 4px 12px;
	border-radius: 5px;
	
	transition: $timing_quick;
	
	
	.icon
	{
		margin-right: 6px;
		font-size: 16px;
	
		color: #0004;
	}
	
	&:hover
	{
		background: lighten(#6a4c93, 48%);
	}
}



.file_list_item_type
{
	margin-left: -6px;
	margin-right: 10px;
	
	color: #0004;
}



.file_list_item_name
{
	white-space: nowrap;
	text-overflow: ellipsis;
}


.file_attached_icon {
	color: #0004;
	margin-right: 7px;
}
