



// TODO
$sidebar--width: 300px;


$app_bar_height: 50px;







$timing_quick: .2s;

$timing_normal: .3s;

$timing_nav: .3s;

$timing_tabs: .2s;



$header_bg: blue;

// border radius
$br--small: 4px;

$br--medium : 8px;

$br--large: 16px;

$br--round: 20px;
// -----------------------



// Accents
$color--eggplant: #6a4c93;

$color--ocean: #1982c4;

$color--spring: #8ac926;

$color--sunflower: #ffca3a;

$color--blush: #C26F6F;

$color--mustard: #DC9A1B;

$color--jade: #3DA654;

$color--apple: #C7372F;

$color--smoke: #d8dbdf;

$color--abyss: #426A8E;
// -----------------------


$color--frost: #F3F5F9;

$color--cloud: #f8f9fa;

$color--snow: #FFFF;

$color--bg-light: #fcfcfc;

$color--charcoal: #212529;

$color--cloud: #f8f9fa;

$color--ash: #4C4C4C;

$color--strawberry: #ff595e;


// -----------------------








// Main APP COLORS

// Common
	$color_pitch_dark: #000000;
	$color_pitch_light: #ffffff;
// -----------------------



// Text
	$color_text_primary_dark: #323338;
	
	$color_text_secondary_dark: #666666;
	
	$color_text_primary_light: #f8f9fa;
// -----------------------



// Primary
	$color_primary_main: #1982c4;
	
	$color_primary_dark: #15608f;
	
	$color_primary_light: #c7e0ef;
// --------------------------



// Secondary
	$color_secondary_main: #ff595e;
	
	$color_secondary_dark: #c86c6c;
	
	$color_secondary_light: #f4bdbd;
	
	$color_secondary_lighter: lighten($color_secondary_light, 7%);
// --------------------------



// Other Accent Colors
	$color_shoal: #ccf4e3;
	
// ---------------------------


// Grayscale
	$color_foggy: #efefef;
	
	$color_misty: #d8dbdf;
	
// ----------------------------



// Leaf
	$color_leaf_main: #8ac926;
	
	$color_leaf_dark: #74b70a;
	
	$color_leaf_light: #c5eb89;
// -------------------------------


// Dandelion
	$color_dandelion_main: #ffca3a;
	
	$color_dandelion_dark: #ffbd0a;
	
	$color_dandelion_light: #ffe588;
// --------------------------------



// Eggplant
	$color_eggplant_main: #6a4c93;
	
	$color_eggplant_dark: #6639A3;
	
	$color_eggplant_light: #7F62A5;
// ---------------------------------