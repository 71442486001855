// filterableSchedule.tsx
	.schedule_filter_vender_btns
	{
		display: flex;
		> span {
			margin: 1rem;
		}
	}

// CalendarSpotLightItem.tsx
	.schedule_filter_accordion
	{

		&_wrapper
		{
			border: 1px solid $color--ash;
			border-radius: $br--small;

			padding: .5rem;
		}

		&_title
		{
			font-size: 1.5rem;
		}
	}

// Calendar.tsx
	.schedule_filter
	{
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-around;
		align-items:flex-start;

		padding: 4rem 0;

		border-radius: $br--large;

		background: $color--cloud;
	}

	.schedule_filter_month_btns
	{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.schedule_filter_spotlight
	{
		align-items:flex-start;
	}

	.schedule_filter_statick_calendar
	{

		.normalDayPaper
		{
			width: calc(45vw/7);
			height: calc(65vh/7);

			outline: 1px solid $color--frost;
		}

		.css-4l7j15 {
			overflow: visible;
			min-width: 320px;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			background-color: #fff;
		}

		.css-j7qwjs {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
		}

		.css-epd502 {
			overflow-x: hidden;
			width: 50vw;
			max-height: 70vh;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			margin: 0 auto;
		}

		.css-169iwlq-MuiCalendarPicker-root {
			overflow-x: hidden;
			width: 50vw;
			height: 70vh;
			max-height: 70vh;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			margin: 0 auto;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
		}

		.css-1wvgxus-MuiCalendarPicker-viewTransitionContainer {
			display: block;
			position: relative;
			overflow-y: visible;
		}

		.css-1n2mv2k {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: space-evenly;
			-webkit-align-items: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			width: inherit;
			align-items: center;
		}

		.css-dhopo2 {
			display: block;
			position: relative;
			overflow-x: visible;
			// min-height: 264px;
		}

		// .css-4l7j15
		// {
		// 	width: 50vw;
		// 	height: 60vh;
		// }

		// .css-169iwlq-MuiCalendarPicker-root
		// {
		// 	width: calc(45vw + 0px);
		// 	height: calc(60vh + 45px);
		// 	max-height: calc(60vh + 45px);

		// 	overflow: visible;

		// 	// outline: 1px solid $color--frost;
		// }


		// .css-epd502 {
		// 	width: inherit;
		// 	max-height: inherit;
		// 	-webkit-font-smoothing: antialiased;
		// 	box-sizing: border-box;
		// 	font-family: 'Roboto', Arial, sans-serif;
		// 	overflow-x: hidden;
		// 	display: flex;
		// 	flex-direction: column;
		// 	margin: 0 auto;

		// 	overflow: visible;
		// }

		// .css-dhopo2 {
		// 	display: block;
		// 	position: relative;
		// 	overflow-x: visible;
		// 	min-height: 264px;

		// 	overflow: visible;
		// }

		// .css-1wvgxus-MuiCalendarPicker-viewTransitionContainer {
		// 	display: block;
		// 	position: relative;
		// 	overflow: visible;
		// }

		// .css-i6bazn {
		// 	overflow: hidden;
		// }
	}