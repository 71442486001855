


.issue_page
{
	//margin-top: $app_bar_height;
	display: flex;
	height: 100%;
	overflow: hidden;
}



.chat_ui
{
	display: flex;
	flex-grow: 1;
}



.chat_contents_zone
{
	position: relative;
	
	overflow-y: auto;
	flex-grow: 1;
	
	//background: lighten(#426A8E, 57%);
	
	// &::before
	// {
	// 	display: block;
	// 	content: ' ';
	// 	position: sticky;
		
	// 	left: 0;
	// 	top: 0;
	// 	margin-top: calc(-100%);
	// 	width: 100%;
	// 	height: 100%;
		
	// 	box-shadow:
	// 		inset 0 0px 1px #0009,
	// 		inset 0 2px 1px #0005;
	// }
}


.chat_contents
{
	display: flex;
	flex-direction: column;
}



.issue_summary
{
	//background: lighten(#8ac926, 49%);
	background: #fff;
	
	padding: 10px;
	
	border-bottom: 1px solid #0003;
	
	z-index: 2;
	
	//box-shadow:
	//	inset 0 -2px 1px #0005,
	//	inset 0 -0px 1px #0009;
	
	
	h2
	{
		width: fit-content;
		
		padding: 3px 13px;
		
		background: #385A8C;
		border: 1px solid #2a4977;
		color: #fff;
		border-radius: 7px;
		
		font-size: 18px;
		font-weight: 600;
		
		margin-right: 8px;
	}
	
	h3
	{
		color: #000a;
		font-weight: 500;
		margin-right: 8px;
	}
	
}

.issue_chat_components_section
{
	display: flex;
	
	justify-content: flex-start;
	align-items: center;
	margin-top: 12px;
}



.issue_heading
{
	display: flex;
	align-items: center;
	
	justify-content: space-between;
	
}

.issue_chat_name_container {
	display: flex;
	flex-direction: row;
	
	align-items: center;
}


.issue_chat_asset_name {
	margin-left: 8px;
	padding: 4px 13px;
	
	border-radius: 15px;
	
	color: #fff;
	background: rgb(160, 157, 157);
}

.issue_chat_date {
	font-weight: 450;
	font-size: 14px;
	
	color: #8e8e8f;
	
	display: flex;
	align-items: center;
}





.chat_details
{
	margin-left: 42px;
	
	list-style: disc;
	
	font-size: 12px;
}



.chat_main
{
	display: flex;
	flex-direction: column;
	
	padding: 0;
	margin: 12px;
	
	overflow: hidden;
	border-radius: 11px;
	
	box-shadow:
		0 0 1px #000a,
		0 1px 4px #0007;
}




.download_dialog_file_size
{
	display: block;
	
	padding: 10px 22px;
}


.download_dialog_download_button
{
	text-decoration: none;
}


.issue_chat_tags_container
{
	display: flex;
	
	justify-content: flex-start;
	align-items: center;
	
	margin-left: 5px;
}


.issue_chat_tags
{
	color: #fff;
	
	width: fit-content;
	
	
	border-radius: 4px;
	
	font-weight: 500;
	font-size: 12px;
	
	margin-right: 6px;
	border: none;
}

.issue_chat_description
{
	color: #464646;
	
	font-size: 13px;
	font-weight: 450;
	
	margin-top: 10px;
	
	padding: 5px;
	border-radius: 5px;
	
	border: 1px solid rgb(219, 219, 219);
}


.issue_chat_components_stack
{
	max-width: 90%;
	display: flex;
	flex-wrap: wrap;
}

.issue_reported_by
{
	font-weight: 450;
	font-size: 15px;
	
	color: #1d1d1d;
}

.issue_chat_collaborators_icon
{
	color: #8ac926;
	
	height: 30px;
	width: 30px;
	
	padding: 3px;
	border-radius: 4px;
	
	background-color: lighten(#8ac926, 45%);
}

.issue_chat_search_icon
{
	color: #1982c4;
	
	height: 30px;
	width: 30px;
	
	padding: 3px;
	border-radius: 4px;
	
	background-color: lighten(#1982c4, 50%);
}

.issue_chat_documents_icon
{
	color: #6a4c93;
	
	height: 30px;
	width: 30px;
	
	padding: 3px;
	border-radius: 4px;
	
	background-color: lighten(#6a4c93, 50%);
}

.issue_chat_history_icon
{
	color: #c26f6f;
	
	height: 30px;
	width: 30px;
	
	padding: 3px;
	border-radius: 4px;
	
	background-color: lighten(#c26f6f, 35%);
}

.issue_history_components_stack
{
	max-width: 90%;
	display: flex;
	flex-wrap: wrap;
}

.history_issue_cards
{
	width: 255px;
	min-width: 200px;
	padding: 4px;
}

.collaborators_panel_container
{
	display: flex;
	flex-direction: column;
	
	justify-content: flex-start;
	align-items: center;
}




.thumb
{
	border-radius: 5px;
	overflow: hidden;
}


.file_list
{
	.thumb
	{
		max-width:  92px;
		max-height: 92px;
		
		margin-right: 8px;
	}
}





