.fileView{
    width: 100%;
}

.fileView__row{
    height: 250px;
    display: flex;
    align-items: center;
}

.fileView__titles{
    display: flex;
    margin-bottom: 5px;
    color: rgb(85, 78, 78);
}

.fileView__titles>div>*{
    margin: 5px;
}

.fileView__titles--left{
    flex: 1;
}

.fileView__titles--right{
    display: flex;
}



.fileCard {
    height: 60px;
    width: 80px;
    border-radius: 10px;
    border: 0px solid rgb(219, 219, 219);
    margin: 5px;
    margin-right: 30px;
}

.fileCard--top {
    height: 70%;
    border-bottom: 0px solid rgb(219, 219, 219);
    display: flex;
    justify-content: center;
    align-items: center;
}

.fileCard--bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 30%;
}

.fileCard--bottom>p {
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
}



.fileItem{
    height: 55px;
    border-bottom: 1px solid rgb(219, 219, 219);
    border-top: 1px solid rgb(219, 219, 219);
    width: 100%;
}

.fileItem>a{
    height: 100%;
    display: flex;
    text-decoration: none;
    color: rgb(85, 78, 78);
}

.fileItem>a>div{
    display: flex;
    align-items: center;
}

.fileItem>a>div>*{
    margin: 10px;
}

.fileItem--left{
    flex: 1;
}