




$editor_height: 80px;

// Minimum 92px so attaching files doesn't resize
$editor_height_mini: 80px;


$editor_padding: 8px;
$editor_border: 1px;

$composition_padding: 8px;
$composition_top_border: 1px;


// Sum padding and other extra space
$composition_extra_space:
	$composition_top_border
	+ 2 * $editor_padding
	+ 2 * $editor_border
	+ 2 * $composition_padding
	- 24px;

$composition_height: $editor_height + $composition_extra_space;
$composition_height_mini: $editor_height_mini + $composition_extra_space;




.chat_composition_zone
{
	display: flex;
	flex-direction: row;
	gap: 8px;
	
	width: 100%;
	// height: $editor_height;
	
	padding: 8px;
	
	//background: lighten(#c26f6f, 30%);
	background: #fff;
	
	border-top: 1px solid #0003;
	
	
	// .public-DraftEditor-content
	// {
	// 	height: $editor_height;
		
	// 	// min-height: 60px;
	// 	// max-height: 180px;
		
	// 	overflow: auto;
	// }
}



.chat_composition
{
	justify-self: stretch;
	// align-self: stretch;
	// flex-grow: 1;
	
	gap: 12px;
	
	overflow-y: auto;
	
	width: 100%;
	height: $composition_height;
	
	//padding: 16px;
	
	border-radius: 5px;
	border: 1px solid #0002;
	
	background: lighten(#c26f6f, 36%);
	
	border-top: 1px solid #0003;
}



.chat_composition_attachments
{
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	gap: 8px;
	
	i
	{
		line-height: 1;
	}
}


.chat_composition_buttons
{
	display: flex;
	flex-direction: column-reverse;
	align-items: stretch;
	justify-content: space-between;
	gap: 8px;
	
	min-width: 88px;
	
	i
	{
		line-height: 1;
	}
}





.chat_composition_buttons_old
{
	display: flex;
	justify-content: space-between;
	
	margin-top: 7px;
	
	.MuiButton-contained
	{
		padding: 1px 11px;
	}
	
	.icon
	{
		font-size: 16px;
		margin-top: 2px;
		margin-right: 7px;
		margin-bottom: -2px;
	}
	
	// .chat_composition_submit
	// {
	// 	$foo: lighten(
	// 		forestgreen,
	// 		7%
	// 	);
		
	// 	background: $foo;
		
	// 	&:hover
	// 	{
	// 		background: darken($foo, 9%);
	// 	}
	// }
}



.chat_selected_files
{
	display: flex;
	flex-direction: row;
	align-items: center;
	
	.icon
	{
		margin-right: 11px;
		font-size: 18px;
	}
}



.editor_wrapper
{
	//background: wheat;
	
	
	// Without this min-height, you have to click on the very first line to get focus
	div[class*='MUIRichTextEditor-placeHolder-']
	{
		min-height: calc($composition_height - 53px);
		padding: 4px 18px;
	}
	
	#mui-rte-container
	{
		margin: 0;
	}
	#mui-rte-editor
	{
		padding: 3px 18px;
		min-height: calc($composition_height - 53px);
	}
	.public-DraftEditor-content
	{
		min-height: calc($composition_height - 73px);
	}
	#mui-rte-toolbar
	{
		padding: 2px 8px;
		
		.MuiIconButton-root
		{
			padding: 4px;
			margin: 0px 2px;
		}
	}
	
	.MUIRichTextEditor-editorContainer-5
	{
		//margin: 0;
		
	}
}




.attach_files_button
{
	.icon
	{
		font-size: 20px;
		margin-top: -2px;
		margin-right: 7px;
		margin-bottom: -6px;
	}
	
	$foo: lighten(
		saturate(
			rgb(106, 76, 147),
			22%
		),
		18%
	);
	
	background: $foo;
	
	&:hover
	{
		background: darken($foo, 9%);
	}
}








.simple_editor
{
	flex-grow: 1;
	
	box-sizing: border-box;
	
	max-height: 400px;
	overflow: auto;
	
	cursor: text;
	
	padding: 8px;
	border: 1px solid #ddd;
	
	border-radius: 4px;
	// margin-bottom: 4px;
	box-shadow: inset 0px 0px 4px -2px #ABABAB;
	background: #fff;
	
	
	@media only screen and (max-height: 900px)
	{
		max-height: 160px;
	}
	
	@media only screen and (max-height: 700px)
	{
		max-height: 120px;
	}
	
	@media only screen and (max-height: 600px)
	{
		max-height: 80px;
	}
}


// div.DraftEditor-root
// {
// 	width: 1100px;
// 	height: 60px;
// 	overflow-y: auto;
	
// 	@media only screen and (max-width: 1200px)
// 	{
// 		width: 985px;
// 	}
// 	@media only screen and (max-width: 1100px)
// 	{
// 		width: 885px;
// 	}
// 	@media only screen and (max-width: 1000px)
// 	{
// 		width: 785px;
// 	}
// 	@media only screen and (max-width: 900px)
// 	{
// 		width: 685px;
// 	}
// 	@media only screen and (max-width: 800px)
// 	{
// 		width: 575px;
// 	}
// 	@media only screen and (max-width: 700px)
// 	{
// 		width: 475px;
// 	}
	
// 	@media only screen and (max-width: 600px)
// 	{
// 		width: 375px;
// 	}
// 	@media only screen and (max-width: 500px)
// 	{
// 		width: 275px;
// 	}
// 	@media only screen and (max-width: 400px)
// 	{
// 		width: 185px;
// 	}
	
// 	@media only screen and (max-width: 350px)
// 	{
// 		width: 170px;
// 	}
// }

// div.DraftEditor-editorContainer div.public-DraftEditor-content
// {
// 	height: 100%;
	
// }

// .simple_editor :global(.public-DraftEditor-content)
// {
// 	max-width: 80%;
// 	max-height: 50px;
// 	overflow: auto;
// }









