

.requestingPO
{
	&__tagContainer
	{
		@include chatBubble();
		
		width: 100%;
		height: 100%;
		
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	&__tagWrapper
	{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		
		margin: 0 .5rem;
		
		> div {
			margin-right: .5rem;
		}
	}
}

