.requisition_collab_page
{
	display: flex;
	height: 100%;
}

.requisition_collab_ui
{
	display: flex;
	flex-grow: 1;
}

.requisition_collab_main
{
	display: flex;
	flex-direction: column;
	
	padding: 0;
	margin: 12px;
	
	border-radius: 8px;
	
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
				rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.requisition_summary
{
		//background: lighten(#8ac926, 49%);
		background: #fff;
	
		padding: 10px;
		
		border-bottom: 1px solid #0003;
		
		z-index: 2;
		
		//box-shadow:
		//	inset 0 -2px 1px #0005,
		//	inset 0 -0px 1px #0009;
		
		
		h2
		{
			width: fit-content;
			
			padding: 3px 13px;
			
			background: rgb(59, 136, 100);
			border: 1px solid #08723a;
			color: #fff;
			border-radius: 7px;
			
			font-size: 18px;
			font-weight: 600;
			
			margin-right: 8px;
		}
		
		h3
		{
			color: #000a;
			font-weight: 500;
			margin-right: 8px;
		}
		
}

.requisition_cost_section
{
	display: flex;
	flex-direction: column;
	
	padding: 5px;
	
	background: rgb(136, 136, 136);
	color: #fff;
	
	font-weight: 400;
	font-size: 14px;
	border-radius: 5px;
	width: fit-content;
}