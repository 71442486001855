


@mixin chatBubble
{
	padding: .5rem;
	border-radius: $br--small;
	background-color: $color--snow;
}


@mixin btn( $bgColor ) 
{
	font-size: 1rem;
	line-height: 1rem;
	
	border: none;
	border-radius: $br--small;
	
	color: $color--cloud;
	background-color: $bgColor;
	box-shadow: 0px 1.5px 0px rgba(0, 0, 0, 0.25);
	
	padding: .3rem 1rem;
}


@mixin mentionTextEditorStyle( $value )
{
	border: 1px solid #ddd;
	border-radius: 2px;
	
	box-sizing: border-box;
	
	padding: .5rem;
	
	cursor: text;

	background: #fefefe;
	
	min-height: $value;

	:global(.public-DraftEditor-content) 
	{
		min-height: $value;
	}
}


@mixin sidebarStyles 
{
	position: absolute;
	
	right: -$sidebar--width;

	width: $sidebar--width;
	height: 100%;
	
	background-color: $color--snow;

	padding-top: 4rem;

	transition: all .5s ease;
}


