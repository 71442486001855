


// Handle thin displays
// TODO: MOVE
@media only screen and (max-width: 900px)
{
	.chat_main
	{
		// overflow-y: auto;
		margin: 0;
		padding: 0;
		border-radius: 0;
	}
	
	.chat_contents_zone
	{
		// overflow-y: unset;
	}
	
	.chat_content_info
	{
		flex-direction: column;
		align-items: flex-start;
	}
	
	.chat_content_info__date
	{
		margin-top: -3px;
		margin-bottom: 8px;
	}
	
	.header_breadcrumbs li a
	{
		font-size: 12px;
		padding: 2px;
	}
	
	
	
	// If the screen is extremely small, we may ned to shrink the editor
	// Or just include the composition component in the scrollable area
	.chat_contents_zone
	{
		// overflow-y: unset;
	}
	
	.chat_composition_zone
	{
		.public-DraftEditor-content
		{
			// height: $editor_height_mini;
		}
	}
	
	.chat_composition
	{
		// height: $composition_height_mini;
	}
}


// Handle short displays
// TODO: MOVE
@media only screen and (max-height: 600px)
{
	.chat_main
	{
		overflow-y: auto;
		margin: 0;
		padding: 0;
		border-radius: 0;
	}
	
	// If the screen is extremely small, we may ned to shrink the editor
	// Or just include the composition component in the scrollable area
	.chat_contents_zone
	{
		// overflow-y: unset;
	}
	
	.chat_composition_zone
	{
		.public-DraftEditor-content
		{
			// height: $editor_height_mini;
		}
	}
	
	.chat_composition
	{
		// height: $composition_height_mini;
	}
}


