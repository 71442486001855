// Login page styling
$primary: #3d89c7;
$secondary: #12446d;
$error: rgb(221, 35, 35);



.login_page
{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	
	width: 100vw;
	height: 100vh;
	margin: 0 0;
	
	background: radial-gradient(
		circle,
		rgba(63, 94, 251, 1) 0%,
		rgba(252, 70, 107, 1) 100%
	);
	
}



.login_page_form
{
	justify-content: center;
	align-items: center;
	
	background: #ffffff;
	
	box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
	
	
	h2
	{
		// margin-bottom: 20px;
		font-size: 30px;
		color: rgba(#000, 0.5);
		
		span
		{
			color: rgba(#000, 1);
			font-size: 28px;
			font-weight: 600;
		}
	}
}



.login_page form
{
	// padding: 30px 30px;

	// display: flex;
	// flex-direction: column;
	// align-items: center;
	// justify-content: space-around;
	
	// width: 500px;
	// height: 500px;
	
	// padding-bottom: 20px;
	// border-radius: 10px;
	
	p
	{
		line-height: 155%;
		margin-bottom: 5px;
		font-size: 22px;
		color: rgb(49, 49, 49);
		opacity: 0.65;
		font-weight: 500;
		max-width: 400px;
		margin-bottom: 40px;
	}
}



.login_page-icon
{
	color:rgba(252, 70, 107, 1);
}








.login_page_avatar_zone
{
	
}


.login_page_avatar
{
	height: 650px;
	
	transform: rotate(-15deg);
}




.login_page_logo
{
	z-index: 10000;
	
	height: 140px;
	width: 140px;
}



.get_started_overlay
{
	position: absolute;
	
	z-index: 100000;
}



.get-started
{
	position: absolute;
	
	// top: 200px;
	// top: 20%;
	// left: 65%;
	
	right: 40px;
	
	z-index: 100000;
	
	h2
	{
		color: #ffffff;
	}
}



.login_page label
{
	font-size: 12.5px;
	color: #000;
	opacity: 0.8;
	font-weight: 400;
}






.input_field
{
	width: 90%;
}


.input_field_email
{
	width: 90%;

	background: lighten(rgba(63, 94, 251, 1), 35%);
}


.eye-icon
{
	cursor: pointer;
	
	&:hover {
		color: #3d89c7;
	}
}


.login_page a.discrete
{
	color: rgba(#000, 0.4);
	font-size: 16px;
	border-bottom: solid 1px rgba(#000, 0);
	padding-bottom: 4px;
	margin-left: auto;
	font-weight: 300;
	transition: all 0.3s ease;
	margin-top: 40px;
	
	&:hover {
		border-bottom: solid 1px rgba(#000, 0.2);
	}
}



.signup-error
{
	color: $error;
}


// Loader styling
.loader
{
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid rgb(59, 108, 182);
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}


@keyframes spin
{
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


