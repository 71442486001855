@import './contents//content_IssuedUser';
@import './contents//content_tags';
@import './nested_sidebar/nested_Item';



.sidebar
{
	position: absolute;
	
	right: -$sidebar--width;
	
	width: $sidebar--width;
	height: 100%;
	
	background-color: $color--snow;
	
	transition: all .5s ease;
}

#check
{
	display: none;
}


label #btn
{
	position: absolute;
	z-index: 1;
	
	padding: 6px 4px 0px 3px;
	border-radius: $br--medium;
	
	background-color: $color--frost;
	
	right: 1rem;
	top:calc(50px + 1.5rem);
	
	cursor: pointer;
	transition: all .5s ease;
}


#check:not(:checked) ~ label #btn
{
	right: calc(#{$sidebar--width} - 3rem);
	transform: rotate(180deg);
}


#check:not(:checked) ~ .sidebar
{
	right: 0;
	
	.IssuedUser___wrapper
	{
	}
}


#check:not(:checked) ~ .communication__chat
{
	margin-right: calc( #{$sidebar--width});
}

