.panel_container {
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 4rem;
  width: 50%;
  height: 100%;
  border-left: 1px solid #000000;
  margin-left: auto;
  overflow-y: auto;
  //   background: #ffffff;
}

.div_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.panel_main {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  border-left: 1px solid #949494;
  z-index: 10;
  min-width: 500px;
  max-width: 80vw;
}

.tags_div {
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
}

.header_main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header_col {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  border-bottom: 1px solid #949494;
  overflow: hidden;
}

.header_asset_info {
  display: flex;
  
  justify-content: center;
  align-items: center;
  
  padding: 4px;
}



.header_row {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  align-self: left;
  margin-bottom: 0.5rem;
  
  padding: 3px;
}

.panel_button_group {
  display: flex;
  
  flex-direction: row;
  justify-content: space-around;
  
  width: 40%;
}

.sydney_header {
  color: #2e2e2e;
  font-size: 25px;
  align-self: center;
  align-content: center;
  justify-content: center;
  
  margin-right: 8px;
}


.component_header {
  color: #6d6d6d;
  font-size: 18px;
  align-self: center;
  align-content: center;
  justify-content: center;
  
  margin-left: 2px;
}

.header_div {
  display: inline-block;
  width: fit-content;
  height: fit-content;
  margin-left: 1rem;
  font-size: 20px;
}

.issue_header {
  border-radius: 0 0 0 10px;
  border: 0.5px solid #949494;
  font-size: 15px;
  width: fit-content;
  height: fit-content;
  margin: 1rem;
  margin-top: 0;
  display: inline-block;
  justify-content: center;
  align-content: center;
  padding: 10px;
  color: #000;
  .icon {
    color: #444;
    margin-right: 0.5rem;
  }
}

.maintenance_card_main_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  height: fit-content;
}

.card_main_info_col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  height: fit-content;
}

.card_dates {
  color: #676879;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.card_date {
  color: #676879;
  margin-right: 0.5rem;
}

.card_highlighted_date {
  color: #ffffff;
  background: #676879;
  padding: 0 0.5rem 0 0.5rem;
  font-size: 14px;
  border-radius: 2px;
  height: fit-content;
}

.card_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: fit-content;
  margin: 1rem;
  & > h4 {
    margin-right: 1rem;
  }
}

.select_mode_buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0 1rem;
}