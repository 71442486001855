@import './captions/chatLog_headline';
@import './contents/content_comment';
@import './contents/content_AddColabborators';
@import './contents/content_RequestPO';


// TODO: Flatten
.chatLog__chatContent
{
	display: flex;
	margin: 1.5rem 2rem;
	
}


.chatLog__chatContent__chatStack
{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	
	margin-left: 1.5rem;
}

