
@import '../contents/content_editCollaborators';
@import '../contents/content_searchLog';
@import '../contents/content_documents';
@import '../contents/content_assetHistory';



#checkEditCollaborators,
#checkSearchLog,
#checkDocuments,
#checkAssetHistory 
{
	display: none;
}

#checkEditCollaborators:checked ~ .edit_collaborators,
#checkSearchLog:checked ~ .search_log,
#checkDocuments:checked ~ .documents, 
#checkAssetHistory:checked ~ .assetHistory
{
	right:0;
}

label #cancelEditCollaborators,
label #cancelSearchLog,
label #cancelDocuments,
label #cancelAssetHistory
{
	position: absolute;
	z-index: 3;
	
	padding: 6px 4px 0px 3px;
	border-radius: $br--medium;
	
	background-color: $color--apple;
	
	right: -3rem;
	top: calc(50px - 1.5rem);
	
	opacity: 0;
	
	cursor: pointer;
	transition: all .5s ease;
}


#checkEditCollaborators:checked ~ label #cancelEditCollaborators,
#checkSearchLog:checked ~ label #cancelSearchLog,
#checkDocuments:checked ~ label #cancelDocuments,
#checkAssetHistory:checked ~ label #cancelAssetHistory
{
	right: calc(#{$sidebar--width} - 3rem);
	opacity: 1;
}

