.issues_page {
	width: 100%;
	height: 100%;
	
	padding: 20px;
}

.issue_cards_grid {
	width: 100%;
}

.issue_cards {
	margin: 10px;
	
	width: 100%;
	padding: 5px;
	
	max-width: 600px;
	min-width: 600px;
	box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, 
				rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}


.issue_card_content {
	width: 100%;
	
	
	display: flex;
	flex-direction: column;
	
	align-items: flex-start;
	padding: 5px;
}

.issue_action_content {
	display: flex;
	
	justify-content: flex-end;
	align-items: center;
}


.issue_card_button {
	color: rgb(46, 95, 141);
	line-height: normal;
}


.issue_card_date {
	font-weight: 450;
	font-size: 14px;
	
	color: #8e8e8f;
	
	display: flex;
	align-items: center;
	
	margin-top: 8px;
}

.issue_card_asset_name {
	margin-left: 2px;
	padding: 5px;
	border-radius: 15px;
	
	background: #b7b7bb;
	color: #fff;
	
	font-weight: 450;
	font-size: 13px;
}


.issue_card_header {
	display: flex;
	
	align-items: center;
	justify-content: space-between;
	width: 100%;
}


.issue_card_header_text_box {
	display: flex;
	align-items: center;
}

.issue_card_name {
	color: rgb(83, 83, 83);
	
	padding: 4px;
	margin-left: 3px;
	
	border-radius: 4px;
	
	max-width: 200px;
	
	font-weight: 500;
}

.issue_card_number {
	color: #fff;
	
	min-width: fit-content;
	margin-left: 3px;
	font-weight: 450;
	
	background: #385A8C;
	border: 1px solid #2a4977;
	border-radius: 4px;
	padding: 4px;
}


.issue_card_report_info {
	display: flex;
	
	align-items: center;
	justify-content: space-between;
	width: 100%;
	
	margin-top: 8px;
}



.issue_card_severity {
	width: 100%;
	
	margin-top: 8px;
	padding: 4px;
	
	font-weight: 500;
	font-size: 15px;
	
	color:rgb(75, 74, 74);
	
	
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.issue_card_severity_value {
	display: flex;
	
	align-items: center;
	justify-content: center;
	margin-right: 5px;
	
	color: #161616;
	cursor: pointer;
}

.severity_low {
	color: rgb(228, 225, 54);
	
	height: 28px;
	width: 28px;
}

.severity_medium {
	color: rgb(233, 163, 1);
	
	height: 27px;
	width: 27px;
}

.severity_high {
	color: rgb(231, 45, 12);
	
	height: 30px;
	width: 30px;
}

.issue_card_components_container {
	width: 100%;
	
	margin-top: 8px;
	padding: 4px;
	
	font-weight: 500;
	font-size: 15px;
	
	color:rgb(75, 74, 74);
	
	
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.issue_card_components_title {
	margin-top: 7px;
}

.issue_card_components_stack {
	margin-left: 8px;
	
	max-width: 90%;
	display: flex;
	flex-wrap: wrap;
}

.issue_card_components {
	margin: 2px;
}

.issue_card_tags_container {
	width: 100%;
	
	margin-top: 8px;
	padding: 4px;
	
	font-weight: 500;
	font-size: 15px;
	
	color:rgb(75, 74, 74);
	
	
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.issue_card_tags {
	padding: 4px;
	border-radius: 4px;
	
	margin-left: 8px;
	font-size: 13px;
	font-weight: 450;
	
	background: #e9e9ee;
}

