


$vessel_heading_bg: #1976d2;




// TODO: MOVE
.image
{
	display: flex;
	align-items: center;
	
	margin: auto;
	//padding: 8px;
	
	//background: white;
	
	// border-radius: 7px;
	
	
	img
	{
		display: block;
		
		width: auto;
		height: auto;
	
		max-width: 100%;
		max-height: 100%;
		
		margin: 0 auto;
	}
}





.vessels_page
{
	h2
	{
		padding: 12px 18px;
	}
}



.vessel_cards
{
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(400px , 1fr));
	grid-auto-rows: 1fr;
	
	grid-row-gap:    24px;
	grid-column-gap: 24px;
	
	padding: 24px;
}



.vessel_card
{
	display: flex;
	flex-direction: column;
	align-items: center;
	
	//width: fit-content;
	//min-width: 400px;
	min-height: 340px;
	
	box-shadow:
		0 0 1px #000,
		0 1px 3px #000a;
	
	border-radius: 6px;
	overflow: hidden;
	
	color: white;
	background: $vessel_heading_bg;
	
	text-decoration: none;
	
	cursor: pointer;
	
	
	transition: $timing_normal all;
	
	
	&:hover
	{
		box-shadow:
			0 0 1px #000,
			0 1px 5px #000c;
	}
	
	
	h3
	{
		padding: 8px 16px;
	}
	
	.image
	{
		transition: $timing_normal all;
		
		//max-width: 400px;
		//max-height: 300px;
		
		filter: saturate(85%) brightness(88%);
	}
	
	&:hover
	{
		background: lighten($vessel_heading_bg, 8%);
		
		.image
		{
			filter: saturate(100%) brightness(108%);
		}
	}
}



















.vessel_dashboard
{
	display: grid;
	
	grid-template-areas:
		'dashboard_profile'
		'dashboard_highlight'
		'dashboard_bottom';
}



@media (min-width: 800px)
{
	.vessel_dashboard
	{
		//grid-template-columns: 1fr 3fr;
		grid-template-areas:
			'dashboard_profile dashboard_highlight'
			'dashboard_bottom  dashboard_bottom';
		
		grid-template-columns: 450px 1fr;
	}
}



.vessel_dashboard_profile
{
	grid-area: dashboard_profile;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	padding: 16px;
	
	h2
	{
		padding: 12px;
		margin-bottom: 12px;
	}
}

.vessel_dashboard_highlight
{
	grid-area: dashboard_highlight;
	background: yellow;
	
	padding: 16px;
}

.vessel_dashboard_bottom
{
	grid-area: dashboard_bottom;
	
	display: flex;
	flex-direction: row;
	
	padding: 6px;
}


.vessel_dashboard_feature
{
	display: flex;
	flex-direction: column;
	
	padding: 6px;
}



.vessel_dashboard_image
{
	width: 400px;
	height: 300px;
	
	border-radius: 7px;
	overflow: hidden;
	
	box-shadow:
		0 0 1px #000,
		0 1px 3px #000a;
}

















.create_issue_button
{
	line-height: 1.2;
	padding: 8px 14px;
	
	background: #DC9A1B;
	
	&:hover
	{
		background: lighten(#DC9A1B, 16%);
	}
	
	p
	{
		margin-top: 3px;
	}
}




@mixin card()
{
	display: flex;
	flex-direction: column;
	
	margin: 6px;
	padding: 16px 22px;
	
	background: cyan;
	
	cursor: pointer;
	
	border-radius: 7px;
	overflow: hidden;
	
	transition: .25s;
	
	box-shadow:
		0 0 1px #0007,
		0 1px 3px #0006;
	
	&:hover
	{
		box-shadow:
			0 0 1px #0009,
			0 1px 5px #000a;
	}
}





.label_cards
{
	display: flex;
	flex-wrap: wrap;
	
	padding: 2px 6px;
}


.label_card
{
	@include card();
	
	overflow: unset;
	
	margin: 3px;
	padding: 1px;
	border-radius: 9px;
	
	font-size: 15px;
	
	white-space: nowrap;
	
	font-weight: 300;
	
	& > span
	{
		margin-left:  8px;
		margin-right: 8px;
		padding: 1px 0px;
		//background: #fff7;
		border-radius: 7px;
		
		color: #fff;
		//box-shadow: 0 0 2px #fffa;
	}
	
	// text-shadow:
	// 	0 2px #000,
	// 	0 -2px #000,
	// 	2px 0 #000,
	// 	-2px 0 #000;
}




.issue_card
{
	@include card();
	
}





.downtime_card
{
	@include card();
	
	background: #ffd;
	
	
	&.resolved
	{
		background: #dff;
	}
}










