

.comment
{
	display: flex;
	
	&__ChatBubble
	{
		@include chatBubble();
		
		display: flex;
		flex-direction: column;
		
		width: fit-content;
		
		margin-bottom: .5rem;
		
		line-height: 1rem;
		font-size: .9rem;
		
		P
		{
			margin: 0;
			padding: 0;
		}
	}
}

