

.form_zone
{
	padding: 16px;
	
	background: magenta;
}


.form
{
	margin: 8px;
	padding: 16px;
	
	background: hotpink;
}

.form_section
{
	margin: 8px;
	padding: 16px;
	
	font-size: 22px;
	
	background: pink;
	
	strong
	{
		margin-right: 12px;
	}
}

.form_question
{
	margin: 6px;
	padding: 6px;
	
	font-size: 18px;
	
	background: salmon;
	
	strong
	{
		margin-right: 12px;
	}
}

.form_input_label
{
	display: flex;
	align-items: center;
}



