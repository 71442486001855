//  Content_Tags
	.tags
	{
		&__wrapper
		{
			margin: 3rem 0rem;
			padding: 0 2rem;
		}

		&__title
		{
			font-size: 1.1rem;
		}

		&__tagesList
		{
			margin: .5rem 0;
			&:last-child{
				margin-bottom: 3rem;
			}
		}
	}
// ============================