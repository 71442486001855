.requisition_page
{
	padding: 20px 40px;
	
	display: flex;
	flex-direction: column;
	height: 100%;
}

.requisition_title
{
	background: rgb(59, 136, 100);
	padding: 8px;
	
	border-radius: 8px;
	color: #fff;
}

.requisition_page_main_content
{
	margin-top: 40px;
	display: flex;
	
	height: 90%;
	
	justify-content: space-between;
}





// Styling for Parts adding section
.requisition_items_section
{
	margin-right: 30px;
	
	height: fit-content;
	width: 65%;
	padding: 10px;
}

.requisition_items_section_heading
{
	font-weight: 500;
	font-size: 16px;
	
	color: rgb(36, 35, 35);
	
	padding-bottom: 5px;
	border-bottom: 1px solid rgb(195, 195, 195);
	
	margin-bottom: 10px;
}





// Styling for Planning section
.requisition_planning_panel
{
	width: 35%;
	height: fit-content;
	min-height: 80%;
	
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, 
				rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	
	padding: 10px;
}

.requisition_planning_heading
{
	font-weight: 500;
	font-size: 16px;
	
	color: rgb(36, 35, 35);
	
	padding-bottom: 5px;
	border-bottom: 1px solid rgb(195, 195, 195);
	
	margin-bottom: 10px;
}




//Styling for New Items Info
.new_item_info_container
{
	border-radius: 5px;
	
	padding: 10px;
	margin-top: 25px;
	margin-bottom: 25px;
	
	max-width: 70%;
	
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
				rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.new_item_section_header
{
	display: flex;
	
	align-items: center;
	justify-content: space-between;
	
	margin-bottom: 15px;
}

.requisition_item_number
{
	color: rgb(44, 43, 43);
	
	padding: 3px 8px;
	margin-right: 10px;
	
	border-radius: 4px;
	border: 2px solid rgb(216, 216, 216);
	
	font-weight: 500;
	font-size: 16px;
}

.requisition_item_info_title
{
	font-size: 16px;
	font-weight: 500;
	
	color: rgb(42, 41, 41);
}

.requisition_manufacturer_fields
{
	display: flex;
	
	justify-content: space-between;
	flex-wrap: wrap;
}

.section_details_title
{
	font-size: 14px;
	
	
	background: rgb(144, 144, 144);
	color: #fff;
	
	width: fit-content;
	padding: 2px 4px;
	border-radius: 2px;
	margin-bottom: 10px;
}

.section_detail_containers
{
	margin-top: 4px;
	margin-bottom: 25px;
}


.requisition_component_selection_modal
{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
	height: 80%;
	
	max-width: 100%;
	
	box-shadow:  0px 2px 6px -1px rgba(0,0,0,.12);
	border-radius: 10px;
	background: #fff;
	
	display: flex;
	padding: 5px;
}

.req_priority_section
{
	margin-bottom: 20px;
}

.requisition_tags_section
{
	margin-top: 20px;
	width: 60%;
}

.req_end_section
{
	margin-top: 60px;
	
	display: flex;
	flex-direction: column;
	
	align-items: flex-end;
}

.req_parts_summary_section
{
	margin-bottom: 70px;
}

.req_est_cost
{
	padding: 5px;
	margin-top: 15px;
	
	background: rgb(61, 61, 61);
	color: #fff;
	
	font-weight: 500;
	font-size: 16px;
	border-radius: 5px;
}