.videoArea
{
	width: 100%;
	align-items: center;
	display: flex;
	flex-direction: column;
	
	height: 400px;
	
	margin-top: 20px;
	margin-bottom: 20px;
}

.scanArea
{
	// transform: scaleX(-1)!important;
}

.video
{
	visibility: hidden;
	width: 0px;
	height: 0px;
	// transform: scaleX(-1)!important;
}

.qr_code_result
{
	margin: 8px;
	margin-bottom: 20px;
	padding: 8px;
	border-radius: 4px;
	color: #fff;
}








// QR-CODE Label Styles

.label_qr_code
{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	
	background: #fff;
	
	width: fit-content;
	height: fit-content;
	
	border-radius: 3mm;
	
	// TODO: Allow resizing
	width:  81mm; //90.3mm;
	height: 26.5mm; //29mm;
}

.label_content
{
	
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	justify-content: space-between;
	
	padding: 2mm;
}

.label_text_content
{
	display: flex;
	flex-direction: column;
	vertical-align: middle;
	align-items: stretch;
	flex: 1;
	
	overflow: hidden;
	max-width: 52mm;
	
	line-height: 1;
	
	padding-right: 8px;
	
	color: #aaa;
	
	
	font-family: Roboto;
	font-size: 5mm;
	font-weight: bold;
}

.label_target_family
{
	padding: 3px 6px;
	border-radius: 6px;
	
	background: #222;
	color: #fff;
	letter-spacing: 1px;
	
	font-weight: 900;
	
	text-align: center;
}

.label_target_type
{
	color: #999;
	font-weight: 900;
	padding-left: 8px;
}


.label_first_line
{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	
	font-size: 4.3mm;
	font-weight: 900;
	
	// padding-right: 6px;
	// padding-bottom: 15px;
}

.label_second_line
{
	color: #555;
	width: 100%;
	
	font-size: 4mm;
	
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	
	// padding: 4px 6px;
	margin: 3px 0;
	
	margin-left: 1mm;
}


.label_third_line
{
	color: #888;
	// letter-spacing: 1px;
	
	font-weight: 900;
	font-size: 3.6mm;
	
	margin-left: 1mm;
}



.label_item_id
{
	margin-left: 3mm;
	
	color: black;
	font-size: 11mm;
	font-weight: bold;
	letter-spacing: 1px;
}



.qr_code
{
	display: flex;
	
	width:  24mm;
	height: 24mm;
	
	position: relative;
}

.qr_code_img
{
	width: 100% !important;
	height: 100% !important;
}

.scanner_modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	background-color: #ffffff;
	
	box-shadow:  0px 2px 6px -1px rgba(0,0,0,.12);
	border-radius: 5px;
	
	padding: 20px;
	
	display: flex;
	flex-direction: column;
	
	justify-content: center;
	align-items: center;
}


.scanner_modal_title {
	background: #2772d3;
	color: #ffffff;
	
	padding: 8px;
	
	border-radius: 5px;
}

.qr_printer_card {
	background: #eee;
	display: flex;
	
	width: fit-content;
	min-width: 90px;
	min-height: 60px;
	
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
				rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	padding: 10px;
	
	margin-top: 25px;
}

.qr_download_button {
	padding: 8px;
	
	border-radius: 2px;
}

.qr_download_icon {
	color: rgb(126, 130, 134);
	
	&:hover {
		font-size: 30px;
		color:rgb(36, 36, 36);
	}
}