


.app_bar
{
	display: flex;
	flex-grow: 1;
	width: 100%;
	justify-content: space-between;
	align-content: center;
}



.header_breadcrumbs
{
	transition: $timing_quick;
	
	
	a
	{
		color: lighten(#272b47, 42%);
		display: flex;
		
		padding: 4px 10px;
		border-radius: 5px;
		
		text-decoration: none;
		font-weight: 400;
	
		transition: $timing_quick;
		
		&.is_last
		{
			color: #000;
			font-weight: 500;
		}
		
		&:hover
		{
			color: #ffffff;
			background: lighten(#1a3d6b, 23%);
		}
	}
	
	
	.MuiBreadcrumbs-separator
	{
		margin: 0 -3px;
		color: #0003;
	}
}


