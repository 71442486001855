.assets_page_container
{
	height: 100%;
	width: 100%;
}




.asset_card_container
{
	background: #fff;
	width: 100%;
	
	margin: 10px;
	
	padding: 5px;
	border-radius: 3px;
	
	box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
	
	display: flex;
	flex-direction: column;
	
	align-items: flex-start;
	justify-content: space-between;
	
	cursor: pointer;
	
	&:hover
	{
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		
		.icon {
			color: $color_primary_main
		}
	}
	
	.icon
	{
		margin-left: 5px;
	}
}

.asset_card_top_section
{
	width: 100%;
	display: flex;
	flex-direction: row;
	
	justify-content: space-between;
}


.asset_card_top_section_main
{
	margin-left: 5px;
	display: flex;
	flex-direction: row;
	
	align-items: center;
	justify-content: flex-start;
}


.asset_card_heading
{
	display: flex;
	flex-direction: column;
	
	align-items: flex-start;
	justify-content: center;
	
	margin-left: 10px;
}



.asset_card_bottom_section
{
	margin-top: 5px;
	width: 100%;
	display: flex;
	flex-direction: row;
	
	justify-content: space-around;
	align-items: center;
}