

.helm
{
	display: flex;
	flex-direction: column;
}


.helm_assets
{
	display: flex;
	flex-direction: column;
}


.helm_asset_list
{
	display: flex;
	flex-direction: column;
}


.helm_asset
{
	display: flex;
	flex-direction: column;
	
	margin: 4px;
	padding: 4px;
	
	background: cyan;
	
	border-radius: 9px;
	
	& > h3
	{
		padding: 5px;
	}
	
	
	& > .helm_components
	{
		padding: 12px;
		max-height: 400px;
		overflow-y: auto;
	}
}


.helm_components
{
	display: flex;
	flex-direction: column;
	
	background: white;
	
	border-radius: 7px;
}


.helm_component_list
{
	display: flex;
	flex-direction: column;
	
	padding: 2px;
}


.helm_component
{
	padding: 2px 6px;
	padding-left: 18px;
	color: #000a;
	
	transition: .15s;
	
	&:hover
	{
		background: #0001;
		
		border-radius: 5px;
	}
}


.helm_component_subcomponent_count
{
	color: #0003;
	margin-left: 8px;
}

