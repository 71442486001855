.components_page
{
	width: 100%;
	height: 100%;
	
	// TODO: Change to something reasonable
	z-index: 10000000000000000;
	
	display: flex;
	flex-direction: column;
	
	justify-content: flex-start;
	align-items: center;
	
	margin-bottom: 100px;
	padding-bottom: 100px;
	
	// overflow-y: auto;
}

.component_tree_container
{
	align-self: flex-start;
	
	margin: 5px;
	height: fit-content;
	
	// TODO: Why?
	// width: 90%;
	
	
	padding: 10px;
	padding-bottom: 55px;
	border-radius: 4px;
	
	.icon
	{
		line-height: 0;
	}
}


.component_drawer
{
	z-index: 900000;
	
	.MuiDrawer-paper {
		background: $color--bg-light;
		border-radius: 16px 16px 0 0;
	}
	
	span
	{
		margin-left: 8px;
		font-size: 14px;
		color: $color_text_primary_dark;
	}
}

.component_card
{
	border-radius: 5px;
	width: 80%;
	height: fit-content;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
	
	margin: 5px;
	padding: 5px 10px;
}

.component_card_info
{
	padding: 0;
	margin: 0 !important;
	height: fit-content;
	
	svg {
		color: $color_misty;
	}
}


.component_card_tabs
{
	button {
		text-transform: none;
		font-weight: 500px;
		font-size: 14px;
	}
}


.select_components_page {
	width: 100%;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	
	align-items: center;
	justify-content: center;
}

.selected_components_container
{
	height: fit-content;
	
	border-radius: 3px;
	
	padding: 5px;
	min-height: 50px;
	width: 100%;
}


.component_chip
{
	background: $color_secondary_main;
	border: 1px solid $color_secondary_dark;
	
	border-radius: 4px;
	padding: 2px 4px;
	
	margin: 5px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}



.css-j5h6pi-MuiPopper-root
{
	z-index: 10000000000 !important;
}