@import "core/reset";
@import "core/base";
@import "core/variables";
@import "core/mixins";
@import "core/chat_mixins";

@import "buttons";
// @import "demo";
@import "fire_table";
@import "helm";
@import "messages";
@import "nav";
@import "files";

@import "tree";
@import "panel";

@import "buttons";
@import "demo";
@import "fire_table";
@import "messages";
@import "nav";
@import "files";
@import "forms";
@import "./communications/communication";
@import "badges";
@import "userCard";
@import "fileButton";
@import "filterableSchedule";
@import "./notification/notification";

//@import "calendar";
@import "calendar3";
@import "map";
@import "auth";

@import "issueDetails";
@import "planIssue";

@import "./settings/settings";

@import "./qrScanner";
@import "./severity.scss";
@import "./systemInfo";





@import 'pairs';




@import 'vessels';

@import 'pages/issues/chat';
@import 'pages/issues/chat_content';
@import 'pages/issues/issue_sidebar';
@import 'pages/issues/chat_composition';
@import 'pages/issues/responsiveness';
@import 'pages/issues/header';
@import 'pages/issues/files';

@import './issuesPage';
@import './dataDashboard';
@import './pages/inventory/inventory';
@import './pages/workOrder/work_order';
@import './pages/requisition/create_requisition_page';
@import './pages/requisition/requisition_collab';
@import './pages/assets/assets';
@import './pages/systems/systems';
@import './pages/components/components';
@import './pages/issues/issue_collab_page';



.stroke_wrapper {
	& > span {
		padding: 1px 2px;
	}
}


// TODO: Normalize all z indexes
.MuiPickersPopper-root
{
	z-index: 200000;
}
