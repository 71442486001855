

.assetHistory
{
	@include sidebarStyles();
	
	
	position: absolute;
	top: 0px;
	
	padding-top: 4rem;
	
	border-left: 1px solid$color--frost;
	
	z-index: 2;
	
	
	.assetCard
	{
		height: 100%;
		
		overflow-y: scroll;
		
		&::-webkit-scrollbar
		{
			display: none;
		}
		
		&__wrapper
		{
			margin: 1rem 1rem;
			padding: 2px;
			
			border-radius: $br--medium;
			border: 1px solid $color--smoke;
			
			> div
			{
				margin: .4rem 0;
			}
		}
		
		&__orderDate
		{
			color: $color--ash;
			font-size: .9rem;
		}
		
		&__title
		{
			color: $color--ash;
		}
		
		&__subContents
		{
			display: flex;
			align-items: center;
			
			font-size: .8rem;
			
			> span
			{
				margin-left: .3rem;
			}
		}
	}
}

