.issue_collab_page
{
	display: flex;
	flex-direction: column;
	
	width: 100%;
	height: 100%;
	
	background: $color_pitch_light;
	
	// border-radius: 10px 10px 10px 10px;
}


.issue_collab_tabs_list
{
	// display: flex;
	// flex-direction: row;
	// justify-content: flex-end !important;
	// align-items: center;
	
	button {
		text-transform: none;
		font-weight: 500px;
		font-size: 14px;
	}
}


.issue_collab_tab_panel
{
	// display: flex;
	// flex-direction: column;
	
	// height: 100%;
	padding: 5px;
	
	background: $color_foggy;
	
	// height: inherit;
}


.cards_container
{
	background: $color_pitch_light;
	border-radius: 5px;
	width: 100%;
	
	padding: 10px;
	
	box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
	margin-bottom: 10px;
}

.part_chip
{
	background: $color_primary_dark;
	
	border-radius: 4px;
	padding: 2px 4px;
	
	margin: 5px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.related_issue_card
{
	margin: 2px;
	padding: 5px;
	margin-bottom: 15px;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
}


.collaborators_list_container
{
	background: $color_pitch_light;
	border-radius: 5px;
	width: 100%;
	
	padding: 10px;
	
	box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
	margin-bottom: 10px;
}


.files_table_container
{
	background: $color_pitch_light;
	border-radius: 5px;
	width: 100%;
	
	padding: 10px;
	
	box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
	margin-bottom: 10px;
}

.add_tags_dialog
{
	min-height: 500px;
}