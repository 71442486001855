.severity_icon {
  margin: auto;
  margin-left: 0.5rem;

  &.high {
    color: $color--apple;
  }

  &.medium {
    color: $color--mustard;
  }
  &.low {
    color: $color--sunflower;
  }
}
