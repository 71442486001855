.icon-bell-o:before {
	content: "";
	display:block;
	height:25px;
	width:25px;
	background-size: 25px 25px;
	background-image: url(ant-design_bell-outlined.svg);
	background-repeat: no-repeat;
}

.notificationapi-button {
    all: initial;
	cursor: pointer;
}

.notification-container {
	height:25px;
	width:25px;
	cursor: pointer;
}