

.commentForm 
{
	display: flex;
	position: sticky;
	bottom: 0;
	
	margin-top: auto;
	padding-bottom: 1rem;
	
	background-color: $color--frost;
	
	width: 100%;
	
	
	&__chatBubble 
	{
		@include chatBubble();
		
		width: 97%;
		height: 100%;
		
		display: flex;
		flex-direction: column;
		
		line-height: 1rem;
		font-size: .9rem;
		
		border: 1px solid $color--smoke;
		border-radius: $br--medium;
		
		// margin: 0 1rem 1rem 1rem;
		padding: 1rem;
		margin-left: auto;
		margin-right: auto;
		
		input
		{
			margin: 0;
			padding: 0;
		}
	}
	
	
	&__file--btn 
	{
		@include btn($color--snow);
		
		border: 1px solid $color--smoke;
		color: $color--charcoal;
		
		display: inline-block;
		cursor: pointer;
		margin: .3rem .3rem .3rem 0rem ;
		transition: all 0.2s;
		
		&:hover 
		{
			transform: translateY(-0.1rem);
			box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2);
		}
		
		&:active 
		{
			transform: translateY(-0.1rem);
			box-shadow: 0 0.5rem .5rem rgba(0, 0, 0, 0.2);
		}
	}

	&__btn-Container 
	{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: right;
	}
	
	&__submit-btn 
	{
		@include btn( $color--jade );
		margin-left: 1rem;
	}
	
	&__resolved-btn 
	{
		@include btn($color--smoke);
		color: $color--charcoal;
		transition: all 0.5s;
		
		&:active
		{
			outline: 2px solid lightseagreen;
			@include btn(none);
			color: $color--charcoal;
			background: $color--snow;
		}
		
		&--active
		{
			@include btn(none);
			color: $color--charcoal;
			background: $color--snow;
			outline: 2px solid lightseagreen;
		}
	}
}

