.system_info_grid {
	display: flex;
	
	justify-content: space-between;
	flex-direction: column;
}

.mui_icon {
	margin: 0;
	padding: 0;
	
	display: flex;
	justify-content: center;
	align-items: center;
}

.issue_button {
	background: rgb(202, 72, 72);
	
	margin-right: 5px;
	
	&:hover {
		background: rgb(219, 71, 71);
	}
}

.work_order_button {
	background:rgb(59, 117, 184);
	
	margin-right: 5px;
	
	&:hover {
		background:rgb(54, 129, 214);
	}
}